import create from 'zustand'
import * as api from '../api/index'
import { devtools } from 'zustand/middleware'


const store = (set, get) => ({
    darkMode: false,
    tabActive: true,
    isMobile: false,
    windowSize: 1500,
    daysOfIgnitorium: 360,
    interval: null,
    checkDaysOfIgnitorium: async () => {
        try{
            const _MS_PER_DAY = 1000 * 60 * 60 * 24
            const start = new Date(`2021, 07, 04`)
            const today = new Date()
            set((state) => ({daysOfIgnitorium: Math.floor((today - start) / _MS_PER_DAY)}))
        }catch(err){
            console.log(err)
        }
    }, 

    changeDarkMode: (current) => {
        set((state) => ({darkMode: !current}))
    },
    checkDarkMode: () => {
        if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
            set((state) => ({darkMode: true}))
        }else{
            set((state) => ({darkMode: false}))
        }
    },
    checkTab: () => {
            if (document.visibilityState === "visible") {
                document.title = 'Fundacja Cor Ignis'
                clearInterval(get().interval);
            }else {
                let x = true
                let newInterval = setInterval(() => {
                    if(x){
                        document.title = '>>Wróć do nas<<'
                    }else{
                        document.title = '>Wróć do nas<'
                        }
                    x = !x
                }, 500)
                set((state) => ({interval: newInterval}))
            }
    },
    checkWindowSize: () => {
        set((state) => ({windowSize: window.innerWidth}))
        if(window.innerWidth < 700){
            set((state) => ({isMobile: true}))
        }else{
            set((state) => ({isMobile: false}))
        }
    },
})


let optionsStore

if(process.env.PRODUCTION === "true"){
    optionsStore = create(store)
}else{
    optionsStore = create(devtools(store))
}


export default optionsStore