import React from 'react'
import assetsStore from '../../../../zustand/assetsStore'

const AboutUsItem = ({item, setItemIndex, index}) => {
  const txtItems = assetsStore(state => state.txtItems)
  return (
    <div className='z-[3] w-[95%] mt-8 sm:mt-0 sm:w-[80%] h-full sm:h-2/3 md:h-full flex items-center dark:bg-bgDarker
     dark:text-white bg-white text-black mx-[2.5%]  rounded-2xl px-4 py-8 shrink-0 sm:cursor-pointer' onClick={index > 0 ? () => setItemIndex(index) : undefined} >
        <div className='h-full pr-8 sm:pr-4 w-full sm:w-2/3 flex flex-col'>
            <h2 className='font-bold text-2xl'>{txtItems?.[`about-title-${item.id}`]}</h2>
            <p className='mt-2 text-xs xs:text-sm md:text-base'>{txtItems?.[`about-desc-${item.id}`]}</p>
        </div>
        <div className='h-[100px] sm:h-full w-[100px] sm:w-1/3 ' style={{backgroundImage: `url(${item.img})`, backgroundSize: 'cover', backgroundPosition: 'center'}}/>
    </div>
  )
}

export default AboutUsItem 