import React, {useState, useEffect} from "react";
import assetsStore from "../../../../zustand/assetsStore";
import optionsStore from "../../../../zustand/optionsStore";
import {motion} from 'framer-motion'

const BrandsItem = ({item}) => {
    const txtItems = assetsStore(state => state.txtItems)
    const currentLanguage = assetsStore(state => state.currentLanguage) 
    const darkMode = optionsStore(state => state.darkMode)
    const isMobile = optionsStore(state => state.isMobile)

    const [isHover, setIsHover] = useState(false)

    return (
            <motion.div style={{order: item?.order}} onClick={() => {window.open(item?.link, "_blank")}} onMouseEnter={() => setIsHover(true)} onMouseLeave={() => setIsHover(false)}
            className="h-[100px] sm:h-[180px] w-[200px] mt-4 sm:mt-0 sm:w-[300px] mx-4 flex-shrink-0 p-4 rounded-xl bg-white cursor-pointer
            sm:hover:bg-bgBright  dark:bg-bgDarker sm:dark:hover:bg-bgDark transition flex justify-center items-center  "
             >
                {!isMobile ? <img src={item.img} style={darkMode ? {filter: isHover ? "brightness(100%)" : "brightness(33%)"} : {filter: isHover ? "brightness(0%)" : "brightness(80%)"}}
                className="pointer-events-none h-[80%] transition  " alt={item?.desc} /> : 
                <img src={item.img} style={darkMode ? {filter: "brightness(100%)"} : {filter:"brightness(0%)"}}
                className="pointer-events-none h-[80%] transition  " alt={item?.desc} />}
            </motion.div>
    )
}

export default BrandsItem