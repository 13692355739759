import React, {useState, useEffect} from "react";
import headerVideo from '../../assets/headerVideoFinal.mp4'
import Button from "../Button/Button";
import optionsStore from "../../zustand/optionsStore";
import assetsStore from "../../zustand/assetsStore";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import {motion, AnimatePresence } from 'framer-motion'
import CountUp from 'react-countup';

const Header = ({title, cta, elementId, main, desc, img}) => {
    const isMobile = optionsStore(state => state.isMobile)
    const [isMouseHover, setIsMouseHover] = useState(false)
    const txtItems = assetsStore(state => state.txtItems)
    const daysOfIgnitorium = optionsStore(state => state.daysOfIgnitorium)
    const fundationIncome = assetsStore(state => state.fundationIncome)
    const numberOfFundraisings = assetsStore(state => state.numberOfFundraisings)

    return(
        <div id="header" className={!main ? "w-full min-h-[600px] h-screen relative flex flex-col items-center overflow-hidden" : 
        "w-full min-h-[600px] h-[125vh] sm:h-screen relative flex flex-col items-center overflow-hidden"}>
            {!main ? <div className="absolute h-5/6 flex flex-col justify-around items-center z-[2] text-white text-center">
                <div className="h-4/6 flex flex-col justify-center items-center">
                    <h1 className=" tracking-wide font-bold w-11/12 sm:w-3/4"
                    style={{fontFamily: 'Playfair Display', fontSize: isMobile  ? `${title?.length > 20 ? 2 : 3}rem` : `${title?.length > 20 ? 3 : 4}rem`}}>{title}</h1>    
                    {!isMobile && <p className="text-base text-left  mt-12 w-2/5">{desc}</p>}
                 </div>
                {isMobile ? <Button secondary onClick={() => {document.getElementById(elementId).scrollIntoView({behavior: "smooth", block: "start"})}} text={cta}/> : 
                <div className="flex flex-col justify-center items-center cursor-pointer" onMouseEnter={() => setIsMouseHover(true)} onMouseLeave={() => setIsMouseHover(false)}
                onClick={() => {document.getElementById(elementId).scrollIntoView({behavior: "smooth", block: "start"})}}>
                    <motion.span initial={{y:0}} animate={{y: isMouseHover ? 20 : 0}}><FontAwesomeIcon icon={faChevronDown}/></motion.span>   
                    <motion.span initial={{y:-10}} animate={{y: isMouseHover ? 5 : -10}}><FontAwesomeIcon icon={faChevronDown}/></motion.span>   
                </div>}
            </div> : 
            <div className="absolute w-full h-full flex flex-col items-center z-[2]">
                <div className="h-[66.6vh] pt-12 sm:py-0 w-[95%] sm:w-full flex flex-col sm:flex-row justify-center items-center z-[2] text-white text-center">
                    <svg style={{animation: 'draw 1.7s linear', strokeDasharray: '447.4772644042969px', transition: "stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0s;"}}
                    className="w-[150px] h-[125px] md:w-[125px] md:h-[100px] lg:w-[150px] lg:h-[125px]"  viewBox="0 0 146 125" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path className="" d="M83.5 41.5L94 52L73 73L52 52L94 10L136 52L73 115L10 52L52 10L62.5 20.5" stroke="#E31E24" stroke-width="14" ></path>
                    </svg>
                    <h1 id="textLogo" className="text-[2.5rem] sm:text-[3.5rem] md:text-[5rem] ml-0 sm:ml-4 mt-4 sm:mt-0 tracking-wide font-bold"
                    style={{fontFamily: 'Playfair Display'}}>{isMobile ? <><p>{txtItems?.['nazwaOrg']}</p></> : txtItems?.['fundation']}</h1>    
                    {/* {isMobile && <p className="text-[0.7rem]  mt-8 sm:mt-4 w-11/12 ">
                        {txtItems?.['fundationDesc']}
                    </p>} */}
                </div>
                <div className="flex-grow w-10/12 sm:w-9/12 lg:8/12 pt-10 sm:pt-0 flex justify-center items-start sm:items-center z-[2] text-white text-center "> 
                    <div className="w-full h-full sm:h-3/5 bg-primarySoft flex flex-col sm:flex-row justify-around items-center rounded-2xl shadow-2xl py-4 sm:py-0 px-0 sm:px-4">
                        <div className="w-11/12 sm:w-1/3 h-1/3 sm:h-11/12 flex flex-col justify-center items-center text-center">
                            <h3 className="text-[2.5rem] "><CountUp start={(fundationIncome / 2)} end={fundationIncome} duration={2}/></h3>
                            <p className="w-4/5 md:w-full text-lg text-black font-bold">{txtItems?.['header-amount']}</p>
                        </div>
                        <div className="w-11/12 sm:w-1/3 h-1/3 sm:h-11/12 flex flex-col justify-center items-center text-center">
                            <h3 className="text-[2.5rem]"><CountUp end={numberOfFundraisings} duration={1.5}/></h3>
                            <p className="w-3/5 md:w-full text-lg text-black font-bold">{txtItems?.['header-fundraisings']}</p>
                        </div>
                        <div className="w-11/12 sm:w-1/3 h-1/3 sm:h-11/12 flex flex-col justify-center items-center text-center">
                            <h3 className="text-[2.5rem] "><CountUp start={(daysOfIgnitorium / 2)} end={daysOfIgnitorium} duration={1.5}/></h3>
                            <p className="w-3/4 md:w-full text-lg text-black font-bold">{txtItems?.['header-days']}</p>
                        </div>
                    </div>
                </div>
            </div>}
            
            <div className="absolute top-0 left-0 w-full h-[83.33vh] overflow-hidden bg-center bg-cover bg-no-repeat" style={{backgroundImage: img && `url(${img})`}}>
                <div className="absolute top-0 left-0 w-full h-full overflow-hidden opacity-80 bg-[#303030]"></div>
                {!img && <video autoPlay loop muted className="object-fill  w-auto sm:w-full h-full">
                    <source src={headerVideo} type="video/mp4" />
                </video>}
            </div>

        </div>
    )
}

export default Header