import React, {useState, useEffect} from "react";
import FaqItem from "../../../components/FaqItem/FaqItem";
import SupportButton from "../../../components/SupportButton/SupportButton.js";
import {motion, AnimatePresence} from 'framer-motion'
import Button from '../../../components/Button/Button'
import Input from '../../../components/Input/Input'
import messagesStore from "../../../zustand/messagesStore";
import optionsStore from "../../../zustand/optionsStore";
import { Link } from "react-router-dom"
import assetsStore from "../../../zustand/assetsStore";
import icon25zl from '../../../assets/25zl.png'
import icon50zl from '../../../assets/50zl.png'
import icon100zl from '../../../assets/100zl.png'
import iconotherzl from '../../../assets/otherzl.png'
import iconsupportzl from '../../../assets/supportzl.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { useInView } from 'react-intersection-observer';
import Confetti from 'react-confetti'

const Support = () => {
    const txtItems = assetsStore(state => state.txtItems)
    const sendDonate = messagesStore(state => state.sendDonate)
    const sendVolounteerMessage = messagesStore(state => state.sendVolounteerMessage)
    const isMobile = optionsStore(state => state.isMobile)
    const windowSize = optionsStore(state => state.windowSize)
    const { ref, inView, entry } = useInView({threshold: 0.7});

    const [formContent, setFormContent] = useState({
        name: "",
        lastName: "",
        email: "",
        msg: "",
        amount: 25,
        terms: false,
    })
    const [errorMsg, setErrorMsg] = useState("")
    const [isSupport, setIsSupport] = useState(false)
    const [isMsg, setIsMsg] = useState(false)
    const [isInView, setIsInView] = useState(false)
    const [showConfetti, setShowConfetti] = useState(false)

    const handleChange = (e) => {
        setFormContent({...formContent, [e.target.name]: e.target.value})
    }

    useEffect(() => {
        setFormContent({...formContent, msg: ""})
        setIsMsg(isSupport)
    }, [isSupport])
    
    useEffect(() => {
        if(inView || windowSize < 700){
            setIsInView(true)
        }
    }, [inView, windowSize])

    useEffect(() => {
        setErrorMsg("")
    }, [formContent])

    const handleCheckVisibility = () => {
        if(document.visibilityState === 'visible'){
            setShowConfetti(true)
            document.removeEventListener("visibilitychange", handleCheckVisibility)
            setTimeout(() => {setShowConfetti(false)}, 10500)
        }
    }

    useEffect(() => {
        if(errorMsg === txtItems?.['support-thanks'] ){
            document.addEventListener("visibilitychange", handleCheckVisibility);
        }
        
    }, [errorMsg])

    const handleSubmit = async (e) => {
        e.preventDefault()
        if(isSupport){
            await setFormContent({
                name: "",
                lastName: "",
                email: "",
                msg: "",
                amount: formContent.amount,
                terms: false,
            })
            await sendVolounteerMessage(formContent, setErrorMsg)
            await setErrorMsg(txtItems?.['support-msg-send'])
        }else{
            if(formContent.terms){
                await setFormContent({
                    name: "",
                    lastName: "",
                    email: "",
                    msg: "",
                    amount: formContent.amount,
                    terms: false,
                })
                await sendDonate(formContent, isMsg, setErrorMsg, txtItems)
            }else{
                setErrorMsg(txtItems?.['support-terms'])
            }
        }
    }

    const handleChaneAmount = (value) => {
        if(value === 0) {
            setIsSupport(true)
        }else{
            setIsSupport(false)
        }
        setFormContent({...formContent, amount: value})
        if(value !== 5){
            document.getElementById('supportContent').scrollIntoView({behavior: "smooth", block: "start"})
        }
    }

    return(
        <>
        <form id="support"  onSubmit={handleSubmit} className="w-full md:w-smContent lg:w-content m-auto flex flex-col items-center pt-[10vh]">
                <div  className="w-full flex flex-wrap items-center justify-around">
                    <SupportButton onClick={() => handleChaneAmount(25)} active={formContent.amount === 25} value={25} icon={icon25zl}/>
                    <SupportButton onClick={() => handleChaneAmount(50)} active={formContent.amount === 50} value={50} icon={icon50zl}/>
                    <SupportButton onClick={() => handleChaneAmount(100)} active={formContent.amount === 100} value={100}  icon={icon100zl}/>
                    <SupportButton onClick={() => handleChaneAmount(5)} icon={iconotherzl} 
                    active={formContent.amount !== 25 && formContent.amount !== 50 && formContent.amount !== 100 && formContent.amount > 0 || formContent.amount === -1}  
                    formContent={formContent} setFormContent={setFormContent}/>
                    <SupportButton onClick={() => handleChaneAmount(0)} active={isSupport} support icon={iconsupportzl}/>
                </div>
                <div ref={ref} id="supportContent" className="w-11/12 md:w-2/3 lg:w-1/2 flex flex-col items-center py-[10vh] " >
                    <div className="w-full relative min-h-[300px] sm:min-h-[200px]  flex items-center overflow-hidden">
                        <AnimatePresence>
                            {isSupport && <motion.div  className="w-full absolute left-0  text-center flex flex-col items-center "
                            initial={{opacity: 0, y: 150}} animate={{opacity: 1, y: 0, transition: {delay: .1, duration: .3}}} exit={{opacity: 0, y: 150, transition: { duration: .3}}}>
                                <h2 className="text-3xl dark:text-white">{txtItems?.['support-volunteering-title']}</h2>
                                <p className="text-base mt-4 dark:text-gray-300">{txtItems?.['support-volunteering-desc']}</p>
                            </motion.div>}
                        </AnimatePresence>
                        <AnimatePresence>
                            {!isSupport && <motion.div className="w-full absolute left-0 text-center flex flex-col items-center "
                            initial={{opacity: 0, y: -150}} animate={{opacity: 1, y: 0, transition: {delay: .2, duration: .3}}} exit={{opacity: 0, y: -150, transition: { duration: .3}}}>
                                <h2 className="text-3xl dark:text-white">{txtItems?.['support-title']}</h2>
                                <p className="text-base mt-4 dark:text-gray-300">{txtItems?.['support-desc']}</p>
                            </motion.div>}
                        </AnimatePresence>
                    </div>
                    {!isMobile ? <motion.div className="w-full mt-8 sm:mt-12 flex flex-col sm:flex-row justify-between" initial={{opacity: 0, y: 40}} animate={{opacity: isInView ? 1 : 0, y: isInView ? 0 : 40, transition: {duration: .3}}}>
                        <Input required maxLength={100} value={formContent.name} onChange={handleChange} name="name" style={{width: isMobile ? '100%' : '47.5%'}} placeholder={txtItems?.['name']} />
                        <Input required maxLength={100} value={formContent.lastName} onChange={handleChange} name="lastName" 
                        style={{width: isMobile ? '100%' : '47.5%', marginTop: isMobile ? '2rem' : '0'}} placeholder={txtItems?.['surname']}/>
                    </motion.div> : 
                    <div className="w-full mt-8 sm:mt-12 flex flex-col sm:flex-row justify-between">
                        <Input required maxLength={100} value={formContent.name} onChange={handleChange} name="name" style={{width: isMobile ? '100%' : '47.5%'}} placeholder={txtItems?.['name']} />
                        <Input required maxLength={100} value={formContent.lastName} onChange={handleChange} name="lastName" 
                        style={{width: isMobile ? '100%' : '47.5%', marginTop: isMobile ? '2rem' : '0'}} placeholder={txtItems?.['surname']}/>
                    </div>}
                    {!isMobile ? <motion.div className="w-full mt-8 sm:mt-12 flex justify-between" initial={{opacity: 0, y: 40}} animate={{opacity: isInView ? 1 : 0, y: isInView ? 0 : 40, transition: {duration: .3, delay: .2}}}>
                        <Input type="email" required maxLength={100} value={formContent.email} onChange={handleChange} name="email" style={{width: '100%'}} placeholder={txtItems?.['mail']}/>
                    </motion.div>: 
                    <div className="w-full mt-8 sm:mt-12 flex justify-between">
                    <Input type="email" required maxLength={100} value={formContent.email} onChange={handleChange} name="email" style={{width: '100%'}} placeholder={txtItems?.['mail']}/>
                    </div>}
                    <motion.div className="overflow-hidden w-full" initial={{maxHeight: 0}} animate={{maxHeight: isMsg ? '100vh' : 0, transition: {duration: .3}}}>
                        <AnimatePresence>
                            {isMsg &&<motion.div className="w-full mt-8 sm:mt-12 flex justify-between" initial={{opacity: 0, y: 40}} exit={{opacity: 0, y: 40}}
                            animate={{opacity: isInView ? 1 : 0, y: isInView ? 0 : 40, transition: {duration: .3, delay: .4}}}>
                                <Input required maxLength={200} value={formContent.msg} onChange={handleChange} name="msg" textarea style={{width: '100%'}} disabled={!isMsg}
                                placeholder={!isSupport ? txtItems?.['support-msg'] : txtItems?.['support-volunteering-msg']}/>
                            </motion.div>}
                        </AnimatePresence>
                    </motion.div>
                    <AnimatePresence>
                    {!isSupport &&<motion.div className="w-full mt-8 sm:mt-12 flex flex-col" initial={{opacity: 0, y: 40}} exit={{opacity: 0, y: 40}}
                    animate={{opacity: isInView ? 1 : 0, y: isInView ? 0 : 40, transition: {duration: .3, delay: .6}}}>
                        <div className="w-full flex items-center text-gray-700 dark:text-gray-300  ">
                            <div onClick={() => setIsMsg(!isMsg)}  className="
                            h-8 aspect-square border-2 border-solid border-gray-400 hover:border-primary cursor-pointer
                            text-primarySoft flex items-center justify-center rounded-lg text-lg mr-2" style={{borderColor: isMsg ? '#E2494E' : undefined}} >
                            {isMsg && <FontAwesomeIcon icon={faCheck}/>}</div>
                            <span>{txtItems?.['support-attach-msg']}</span>
                        </div>
                        <div className="w-full flex items-center text-gray-700 dark:text-gray-300 mt-4">
                            <div onClick={() => {setFormContent({...formContent, terms: !formContent.terms})}} className="
                            h-8 aspect-square border-2 border-solid border-gray-400 hover:border-primary cursor-pointer
                            text-primarySoft flex items-center justify-center rounded-lg text-lg mr-2 " style={{borderColor: formContent.terms ? '#E2494E' : undefined}}>
                            {formContent.terms  && <FontAwesomeIcon icon={faCheck}/>}</div>
                            <span>{txtItems?.['support-do-you-accept']}
                            &nbsp;<a className="underline" href="https://drive.google.com/file/d/1-d4AOeNixPf3JOG-XpvBP0wbQIJvXAF4/view" target="_blank">{txtItems?.['support-transfer-terms']}</a> {txtItems?.['support-and']}  
                            &nbsp;<a className="underline" href="https://corignis.org/terms" target="_blank">{txtItems?.['support-rodo']}</a>? </span>
                        </div>
                        </motion.div>}
                    </AnimatePresence>
                    {!isMobile ? <motion.div className="w-full mt-8 sm:mt-12 flex justify-center" initial={{opacity: 0, y: 40}} animate={{opacity: isInView ? 1 : 0, y: isInView ? 0 : 40, transition: {duration: .3, delay: .8}}}>
                        <Button submit style={{width: isMobile ? '92%' : '50%'}} text={txtItems?.['send']}/>
                    </motion.div> : 
                    <div className="w-full mt-8 sm:mt-12 flex justify-center" >
                        <Button submit style={{width: isMobile ? '92%' : '50%'}} text={txtItems?.['send']}/>
                    </div>}
                    <div className="w-full text-center h-8 mt-4 text-primarySoft overflow-hidden">
                        {errorMsg.length > 0 &&
                            <motion.span initial={{opacity: 0, y: 40}} animate={{opacity: 1, y: 0}} exit={{opacity: 0, y: 40}}>
                                {errorMsg}
                            </motion.span>
                        }
                    </div>
                </div>
                <div className="w-[95%] sm:w-4/5 my-[5vh] mx-auto flex flex-col items-center">
                    <FaqItem id={1} title={txtItems?.['faq-mTransfer-title']} content={<>
                    <p>{txtItems?.['faq-mTransfer-name']}</p>
                    <p>{txtItems?.['faq-mTransfer-address']}</p>
                    <p>{txtItems?.['faq-mTransfer-accountNumber']}</p>
                    <p>{txtItems?.['faq-mTransfer-swift']}</p>
                    <br />
                    <p>{txtItems?.['faq-payPal-desc']}</p>
                    <p>{txtItems?.['faq-payPal-mail']}</p>
                    </>}/>
                    <FaqItem id={2} title={txtItems?.['faq-statut-title']} content={<>{txtItems?.['faq-statut-desc']} <span className="underline"><Link to='/terms'>{txtItems?.['faq-statut-link']}</Link></span></>}/>
                    <FaqItem id={3} title={txtItems?.['faq-payment-title']} content={<>{txtItems?.['faq-payment-desc1']}{txtItems?.['faq-payment-desc2']}
                    <span className="underline"><a href="https://drive.google.com/file/d/1-d4AOeNixPf3JOG-XpvBP0wbQIJvXAF4/view" target="_blank"> {txtItems?.['faq-payment-link']}</a></span></>}/>
                    <FaqItem id={4} title={txtItems?.['faq-rodo-title']} content={<>{txtItems?.['faq-rodo-desc']} <span className="underline"><Link to='/terms'>{txtItems?.['faq-rodo-link']}</Link></span></>}/>
                    <FaqItem id={5} title={txtItems?.['faq-more-title']} content={<>
                        <p>{txtItems?.['faq-more-desc']}</p>
                    </>}/>
                </div>
        </form>
        <AnimatePresence>
            {errorMsg === txtItems?.['support-msg-send'] && <div className="fixed left-0 top-0" >
                <Confetti
                width={window.innerWidth}
                height={window.innerHeight}
                recycle={false}
                />
            </div>}
            {showConfetti && <div className="fixed left-0 top-0" >
                <Confetti
                width={window.innerWidth}
                height={window.innerHeight}
                recycle={false}
                numberOfPieces={400}
                />
            </div>}
        </AnimatePresence>
        </>
    )
}

export default Support