import React, {useState, useEffect} from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import {motion, AnimatePresence} from 'framer-motion'
import { chevron, faqItem } from "../../animations/animations";

const FaqItem = ({title, content, id}) => {
    const [isOpen, setIsOpen] = useState(false)
    const [heightOfElement, setHeightOfElement] = useState(0)
    const [isHeightChecked, setIsHeightChecked] = useState(false)

    useEffect(() => {
        setHeightOfElement(document.getElementById(`faq${id}`)?.clientHeight)
        setTimeout(() => {setIsHeightChecked(true)}, 500)
    }, [])


    return(
        <motion.div className="w-full border-4 border-solid border-primarySoft hover:border-primary transition rounded-xl 
        overflow-hidden mt-[2.5vh] text-black dark:text-white " 
        initial={{height: '90px', transition: {duration: 0.4, type: 'linear'}}} 
        animate={{height: isOpen ? `${90 + heightOfElement}px` : '90px', transition: {duration: 0.4, type: 'linear'}}}>
            <div className="w-full rounded-xl h-20 flex cursor-pointer bg-bgBright dark:bg-bgDark overflow-hidden z-[2]" onClick={() => {setIsOpen(!isOpen)}}>
                <div className="w-20 flex justify-center items-center z-[2] bg-bgBright dark:bg-bgDark">
                    <div className="w-10 sm:w-14 h-10 sm:h-14 flex justify-center items-center rounded transition bg-primarySoft hover:bg-primary text-white dark:text-bgDark text-lg">
                        <motion.span variants={chevron} initial="initial" animate={isOpen ? 'animate' : 'initial'}>
                            <FontAwesomeIcon icon={faChevronDown} />
                        </motion.span>
                    </div>
                </div>
                <div className="bg-bgBright dark:bg-bgDark grow flex items-center font-bold text-lg z-[2]">
                    {title}
                </div>
            </div>
            {isHeightChecked ? 
                <> <AnimatePresence>
                    {isOpen && 
                        <motion.div id={`faq${id}`} variants={faqItem} initial="initial" animate={isOpen ? 'animate' : 'initial'} exit="initial" >
                            <hr className="w-[95%] m-auto h-1 bg-primarySoft border-none"/>
                            <div className="p-4 md:p-8 z-[1]" >
                                {content}
                            </div>
                        </motion.div>
                    } 
                </AnimatePresence></> : 
                <div id={`faq${id}`} style={{opacity: 0, y: "-100%"}} className="p-4 md:p-8 z-[1]" >
                    {content}
                </div>
            }
        </motion.div>
    )
}

export default FaqItem