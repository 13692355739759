import React, {useState, useEffect} from "react";
import Button from "../../components/Button/Button";
import optionsStore from "../../zustand/optionsStore";
import { useNavigate, useLocation } from "react-router-dom";
import Support from '../MainView/Support/Support'
import { ReactComponent as BugSvg } from '../../assets/bug.svg'
import assetsStore from "../../zustand/assetsStore";

const WorkInProgress = () => {
    const checkLoginLink = optionsStore(state => state.checkLoginLink)
    const navigate = useNavigate()
    const login = useLocation()
    const txtItems = assetsStore(state => state.txtItems)
    useEffect(() => {
        if(document.referrer === 'https://admin.corignis.org/' && login.search.slice(0, 7) === '?login='){
            checkLoginLink(login.search.slice(7, 19), navigate)
        }
    }, [])
    return(
        <div className="w-full min-h-screen flex flex-col items-center">
            <div className="w-11/12 min-h-screen flex flex-wrap md:flex-nowrap  flex-col justify-around items-center pt-[10vh] overflow-hidden text-center">
                <BugSvg className="max-h-[40vh] max-w-full" />
                <h1 className="text-black dark:text-gray-200 text-2xl font-bold text-center">
                    {navigator.language.slice(0, 2) === "pl" ? "Strona Cor Ignis jest w trakcie prac technicznych" : "Cor Ignis website is undergoing technical work"}
                </h1>
                {Object.keys(txtItems).length > 0 &&<Button onClick={() => {document.getElementById('support').scrollIntoView({behavior: "smooth", block: "start"})}} 
                text={navigator.language.slice(0, 2) === "pl" ? "Cały czas możesz nas wesprzeć" : "You can still support us"} />}
            </div>
            {Object.keys(txtItems).length > 0 && <Support />}
        </div>
    )
}

export default WorkInProgress