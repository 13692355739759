import React, {useState, useEffect} from "react";

const SvgMap = ({items, currentItem, setCurrentItem}) => {

    const handleClick = (e) => {
        if(items[e.target.id]?.length > 0){
            if(currentItem === e.target.id){
                setCurrentItem("all") 
            }else{
                setCurrentItem(e.target.id)
            }
        }
    }

    return (
        <div className='w-full h-full overflow-hidden'> 
            <svg className='w-full h-full sm:scale-y-125 md:scale-100' viewBox="0 0 190 170" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path id="ru" style={{cursor: items["ru"]?.length > 0 ? "pointer" : undefined, fill: currentItem === "ru" ? "#E2494E" : "#CCCCCC", transition: 'fill .4s', filter: !items["ru"] ? 'brightness(0.5)' : undefined}} onClick={handleClick}
                 d="M62.5398 55.9099L71.5098 58.3299L73.2398 60.5299L72.8498 66.5099L51.5498 65.3199L54.0398 59.9399L57.7198 59.0799L60.2698 55.6499L60.6598 55.7399L58.2198 59.2299L60.9198 60.1599L62.2198 59.7799L62.5398 55.9099Z" fill="#CCCCCC" stroke="white" stroke-width="0.5"/>
                <path id="ua" style={{cursor: items["ua"]?.length > 0 ? "pointer" : undefined, fill: currentItem === "ua" ? "#E2494E" : "#CCCCCC", transition: 'fill .4s', filter: !items["ua"] ? 'brightness(0.5)' : undefined}} onClick={handleClick}
                d="M176.91 143.92L172.39 144.08L167.35 147.63L160.83 148.51L155.36 153.56L157.15 151.87L156.81 150.3L154.25 152.75L155.41 157.47L158.34 161.16L165.87 160.42L164.65 163.56L158.44 163.24L149.02 169.63L144.82 168.41L145.53 163.25L138.47 160.33L146.27 155.1L145.79 153.65L138.26 153.78L133.91 151.85L135.11 150.41L132.05 149.19L137.48 149.97L138.94 148.59L135.35 148.33L133.43 142.72L134.19 148.26L131.9 148.11L132.11 147.01L131.03 148.47L126.94 149.22L123.05 155.87L119.07 157.27L119.6 161.7L117.56 160.17L113.23 161.95L109.54 159.88L111.47 159.23L114.49 154.1L114.56 150.13L122.46 150.48L120.75 146.5L118.69 145.17L118.47 142.14L115.75 139.97L115.69 135.22L105.07 130.04L98.8003 132.25L96.6903 132.81L95.7303 134.94L87.7503 137.63L85.0603 135.56L75.7903 134.02L73.5903 135.4L68.5703 130.78L71.3103 123.97L73.4303 124.07L72.4403 118.46L81.7603 108.78L81.8003 105.14L78.5003 98.2199L83.6003 94.4199L93.1903 93.8099L102.32 95.6799L106.08 98.65L109.34 97.2399L113.03 99.1299L115.53 97.03L117.18 99.6799L122.66 98.65L125.25 100.94L126.67 94.1199L128.85 92.1299L133.46 91.8699L135.87 92.4199L137.98 89.6099L146.74 89.1899L151.2 95.3699L149.34 96.4699L149.96 101.05L155.69 101.6L157.51 103.56L159.24 110.49L162.78 110.07L166.17 112.16L171.58 110.03L175.79 115.2L177.21 113.81L189.49 118.77L189.67 122.13L186.83 124.63L188.97 126.54L187.55 126.68L186.55 128.89L188.66 132.16L187.45 135.99L181.53 136.32L177.95 138.77L176.91 143.92ZM135.13 152.61L136.06 153.09L131.74 150.96L135.13 152.61Z" fill="#CCCCCC" stroke="white" stroke-width="0.5"/>
                <path id="sk" style={{cursor: items["sk"]?.length > 0 ? "pointer" : undefined, fill: currentItem === "sk" ? "#E2494E" : "#CCCCCC", transition: 'fill .4s', filter: !items["sk"] ? 'brightness(0.5)' : undefined}} onClick={handleClick}
                d="M71.3096 123.97L68.5696 130.78L65.8096 131.37L63.9896 129.29L57.5096 129.54L53.8796 133.39L51.6896 132.62L46.0696 134.86L45.6196 137L39.1396 137.17L34.9996 134.81L33.0996 130.96L33.6896 128.81L34.9196 126.34L39.1096 125.86L41.8196 122.07L46.3496 119.45L48.4796 120.6L50.4496 118.55L52.6896 120.89L52.5696 122.62L54.5996 122.85L56.6596 120.75L65.2596 120.48L71.3096 123.97Z" fill="#CCCCCC" stroke="white" stroke-width="0.5"/>
                <path id="pl" style={{cursor: items["pl"]?.length > 0 ? "pointer" : undefined, fill: currentItem === "pl" ? "#E2494E" : "#CCCCCC", transition: 'fill .4s', filter: !items["pl"] ? 'brightness(0.5)' : undefined}} onClick={handleClick}
                d="M51.5502 65.3199L72.8502 66.5099L76.9202 68.3099L77.6902 71.2999L80.5901 84.4899L75.6002 89.83L78.8202 92.53L78.5002 98.2199L81.8002 105.14L81.7602 108.78L72.4402 118.46L73.4302 124.07L71.3102 123.97L65.2602 120.48L56.6602 120.75L54.6002 122.85L52.5702 122.62L52.6902 120.89L50.4502 118.55L48.4802 120.6L46.3502 119.45L44.5302 115.62L38.2302 113.14L38.7302 111.13L36.8002 111.69L33.2002 109.87L33.9302 111.87L31.5702 113.29L28.6802 109.91L30.0901 108.32L29.1702 107.45L27.3202 107.92L20.4902 103.63L19.2502 105.29L20.6402 101.07L17.8502 94.7999L18.8602 92.08L17.9702 87.1699L14.6602 83.28L16.5602 79.6899L15.5402 73.7L17.7302 74.7199L17.5602 72.6299L15.2302 72.09L15.2202 71.1799L28.5202 67.27L31.0402 64.2299L39.6802 61.1699L42.9202 60.9199L45.8502 62.71L43.6802 62.0099L46.3702 66.3599L51.5502 65.3199Z" fill="#CCCCCC" stroke="white" stroke-width="0.5"/>
                <path id="lv" style={{cursor: items["lv"]?.length > 0 ? "pointer" : undefined, fill: currentItem === "lv" ? "#E2494E" : "#CCCCCC", transition: 'fill .4s', filter: !items["lv"] ? 'brightness(0.5)' : undefined}} onClick={handleClick}
                d="M103.74 28.37L106.95 31.3L105.68 36.84L107.09 36.75L108.8 40.52L109.1 45.41L105.25 49.55L101.72 49.17L98.6298 51.12L92.3598 45.87L88.3698 44.72L86.8198 42.16L81.9698 43.95L68.2598 42.22L61.2598 46.29L61.4298 37.11L65.8598 27.83L71.4198 25.9L76.3598 33.83L78.7898 35.3L83.7398 31.84L83.3298 24.05L89.7998 21.79L94.5698 24.46L97.7398 28.16L101.14 27.35L103.74 28.37Z" fill="#CCCCCC" stroke="white" stroke-width="0.5"/>
                <path id="lt" style={{cursor: items["lt"]?.length > 0 ? "pointer" : undefined, fill: currentItem === "lt" ? "#E2494E" : "#CCCCCC", transition: 'fill .4s', filter: !items["lt"] ? 'brightness(0.5)' : undefined}} onClick={handleClick}
                d="M98.6295 51.1201L97.7095 54.9901L99.8595 55.8102L93.6795 59.9701L91.5795 66.8001L92.9395 68.8102L91.3295 68.7802L90.9995 67.2502L83.2995 71.8302L77.6895 71.3001L76.9195 68.3102L72.8495 66.5102L73.2395 60.5302L71.5095 58.3302L62.5395 55.9102L61.2595 46.2902L68.2595 42.2202L81.9695 43.9502L86.8195 42.1602L88.3695 44.7202L92.3595 45.8701L98.6295 51.1201ZM60.6595 55.7401L60.2695 55.6501L61.7195 51.7302L60.6595 55.7401Z" fill="#CCCCCC" stroke="white" stroke-width="0.5"/>
                <path id="ee" style={{cursor: items["ee"]?.length > 0 ? "pointer" : undefined, fill: currentItem === "ee" ? "#E2494E" : "#CCCCCC", transition: 'fill .4s', filter: !items["ee"] ? 'brightness(0.5)' : undefined}} onClick={handleClick}
                d="M108.19 3.14984L109.12 4.59985L104.29 12.2998L104.75 19.5898L106.61 24.0498L103.74 28.3698L101.14 27.3499L97.7398 28.1599L94.5698 24.4598L89.7998 21.7899L83.3298 24.0498L84.7198 17.8899L81.9298 19.0198L79.5898 17.7998L77.8498 13.9698L79.0098 12.2998L77.3298 10.5598L77.7498 6.95984L81.7198 5.68985L83.7198 3.29984L90.8798 2.65985L91.3298 1.08984L101.2 3.59985L107.38 4.07983L108.19 3.14984ZM73.9098 11.7798L71.3398 13.5598L70.4498 11.3098L68.0598 10.2599L72.0598 8.38986L73.8098 9.63986L73.9098 11.7798ZM76.7398 15.3698L75.1598 13.9598L76.6598 14.0999L76.7398 15.3698ZM71.8398 14.4499L76.5998 16.6498L70.1898 19.6399L67.6598 23.2798L68.9498 20.4499L66.8898 19.0699L66.7598 16.0498L71.8398 14.4499Z" fill="#CCCCCC" stroke="white" stroke-width="0.5"/>
                <path id="cz" style={{cursor: items["cz"]?.length > 0 ? "pointer" : undefined, fill: currentItem === "cz" ? "#E2494E" : "#CCCCCC", transition: 'fill .4s', filter: !items["cz"] ? 'brightness(0.5)' : undefined}} onClick={handleClick}
                d="M19.2497 105.29L20.4897 103.63L27.3197 107.92L29.1697 107.45L30.0897 108.32L28.6797 109.91L31.5697 113.29L33.9297 111.87L33.1997 109.87L36.7997 111.69L38.7297 111.13L38.2297 113.14L44.5297 115.62L46.3497 119.45L41.8197 122.07L39.1097 125.86L34.9197 126.34L33.6897 128.81L30.9297 126.8L27.6497 127.22L20.9797 124.74L18.4497 128.81L14.1297 128.77L12.5497 127.1L4.90967 120.45L2.94968 117.08L3.76968 115.45L0.929688 111.19L2.18967 112.46L4.01968 110.23L6.66968 110.09L16.2797 104.86L15.9497 103.38L19.2497 105.29Z" fill="#CCCCCC" stroke="white" stroke-width="0.5"/>
                <path id="by" style={{cursor: items["by"]?.length > 0 ? "pointer" : undefined, fill: currentItem === "by" ? "#E2494E" : "#CCCCCC", transition: 'fill .4s', filter: !items["by"] ? 'brightness(0.5)' : undefined}} onClick={handleClick}
                d="M109.1 45.4102L113.46 47.8301L115.05 46.8802L117.37 47.8701L118.09 50.9202L123.15 49.0002L127.69 52.2802L127.04 55.4102L128.16 58.4301L126.96 61.5602L129.34 63.4002L128.82 64.9502L131.03 68.3601L133.88 70.2602L133.39 72.7802L138.08 74.1101L139.81 77.2102L136.01 80.9002L131.13 79.7301L130.06 81.7401L132.12 84.6101L133.46 91.8701L128.85 92.1302L126.67 94.1201L125.25 100.94L122.66 98.6502L117.18 99.6801L115.53 97.0302L113.03 99.1302L109.34 97.2401L106.08 98.6502L102.32 95.6801L93.1896 93.8102L83.5996 94.4201L78.4996 98.2201L78.8196 92.5302L75.5996 89.8302L80.5896 84.4901L77.6896 71.3001L83.2996 71.8302L90.9996 67.2502L91.3296 68.7802L92.9396 68.8102L91.5796 66.8001L93.6796 59.9701L99.8596 55.8102L97.7096 54.9901L98.6296 51.1201L101.72 49.1702L105.25 49.5501L109.1 45.4102Z" fill="#CCCCCC" stroke="white" stroke-width="0.5"/>
            </svg>
        </div>
    )
}

export default SvgMap