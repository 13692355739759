import React, {useState, useEffect} from "react";
import Header from '../../components/Header/Header'
import assetsStore from "../../zustand/assetsStore";
import TermsItem from './TermsItem/TermsItem'

const Terms = () => {
    const txtItems = assetsStore(state => state.txtItems)
    const statut = assetsStore(state => state.statut)
    const rodo = assetsStore(state => state.rodo)
    const currentLanguage = assetsStore(state => state.currentLanguage)

    return (
        <div id="Terms" className='w-full h-full flex flex-col'>
            <Header title={txtItems?.['nav-terms']} elementId='Content' cta={txtItems?.['learnMore']} />
            <div id="Content" className="w-full md:w-smContent lg:w-content m-auto mb-12 px-4 dark:text-white flex flex-col sm:flex-row justify-between ">
                <div className='w-full sm:w-2/3 flex flex-col order-2 sm:order-1'>
                    <h2 className='text-3xl font-bold mb-4 mt-8 sm:mt-0'>{txtItems?.['documents-statut']}</h2>
                    <div className='mt-4'>
                        {statut?.map((item, index) => <TermsItem item={item} index={index}/>)}
                    </div>
                    <h2 className='text-3xl font-bold mt-12'>{txtItems?.['documents-rodo']}</h2>
                    <div className='mt-4'>
                        {rodo?.map((item, index) => <TermsItem item={item} index={index}/>)}
                    </div>
                </div>
                <div className='w-full sm:w-1/4 flex flex-col  order-1 sm:order-2 '>
                    <h3 className='text-2xl font-bold'>{txtItems?.['documents-contents']}</h3>
                    <h2 className='text-xl  mt-4'>{txtItems?.['documents-statut']}:</h2>
                    {statut?.map((item, index) => <p onClick={() => {document.getElementById(`doc${item._id}`).scrollIntoView({behavior: "smooth", block: "start"})}}
                     className="underline cursor-pointer mt-2 sm:mt-0">{`${index + 1}. ${item.name[currentLanguage]}`}</p>)}
                    <h2 className='text-xl  mt-4'>{txtItems?.['documents-rodo']}:</h2>
                    {rodo?.map((item, index) => <p onClick={() => {document.getElementById(`doc${item._id}`).scrollIntoView({behavior: "smooth", block: "start"})}} 
                    className="underline cursor-pointer mt-2 sm:mt-0">{`${index + 1}. ${item.name[currentLanguage]}`}</p>)}
                </div>
            </div>
        </div>
  )
}

export default Terms