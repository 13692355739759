import React, {useState, useEffect} from "react";
import {motion, AnimatePresence, useScroll} from 'framer-motion'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";
import {useNavigate} from 'react-router-dom'
import CountUp from 'react-countup';
import * as fontIcons from '@fortawesome/free-solid-svg-icons'
import assetsStore from "../../../../zustand/assetsStore";

const NewsItem = ({item}) => {
    const navigate = useNavigate()
    const [isOpen, setIsOpen] = useState(false)
    const txtItems = assetsStore(state => state.txtItems)

    return (
        <div className='w-[300px] h-[450px] mr-[15px] sm:mr-[50px] flex-shrink-0 rounded-xl bg-white dark:bg-bgDarker overflow-hidden shadow dark:shadow-xl' style={{order: item.order}}>
            <motion.div className="w-full h-5/6 bg-center cursor-pointer bg-cover" style={{backgroundImage: `url(${item.img})`}}
            initial={{height: '390px'}} animate={{height: isOpen ? '100px' : '390px', transition: {duration: .4}}} onClick={() => item.expandable ? setIsOpen(!isOpen) : undefined}>
                {item.expandable && <div className="w-full flex justify-end">
                    <div className="h-[30px] mt-2 mr-2 aspect-square rounded-full flex justify-center items-center bg-white dark:bg-bgDarker">
                        <FontAwesomeIcon icon={isOpen ? faMinus : faPlus} />
                    </div>
                </div>}
            </motion.div>
            <motion.div className="w-full" 
            initial={{height: '60px'}} animate={{height: isOpen ? '290px' : '60px', transition: {duration: .4}}}>
                <h2 className="w-full h-[50px] font-bold text-base p-2 ">{item.title}</h2>
                <p className="w-full h-[230px] p-2 text-[0.8rem]">{item.description}</p>
                <div className="w-full px-2 h-[40px] text-xs">
                    {item.promote === "text" && 
                        <div className="w-full h-full flex justify-center items-center text-center text-xl text-primarySoft font-bold">
                            {item.promoteValue.text}
                        </div>}
                    {item.promote === "counter" && 
                        <div className="w-full h-full flex justify-center items-center text-center text-xl text-primarySoft font-bold">
                            <span>
                            {item.promoteValue.counter.before} &nbsp;
                            <CountUp 
                            start={(item.promoteValue.counter.value / 2)} 
                            end={isOpen ? item.promoteValue.counter.value : item.promoteValue.counter.value / 2} duration={2}/> 
                            &nbsp; {item.promoteValue.counter.after}
                             </span>
                        </div>}
                    {item.promote === "icons" && 
                        <div className="w-full flex justify-around items-center">
                            {item.promoteValue.icons?.map((i) => (
                                <div className=" h-full flex flex-col items-center justify-between text-primarySoft text-center" style={{width: `${100 / item.promoteValue.icons}%`}}>
                                    <span><FontAwesomeIcon icon={fontIcons[i.icon]} /></span>
                                    <span>{i.text}</span>
                                </div>
                            ))}
                        </div>}
                </div>
                {item.type !== "static" && <div className="w-full h-[30px] flex justify-end cursor-pointer" onClick={() => item.type === "blog" ? navigate(`/blog/${item._id}`) : window.open(item.typeValue.link, '_blank')}>
                    <div className="w-1/2 h-full flex text-white justify-center items-center rounded rounded-tl-xl bg-primarySoft">{txtItems?.[`readMore2`]}</div>
                </div>}
            </motion.div>
        </div>
    )
}

export default NewsItem
