import React, {useState, useEffect} from "react";
import cookiesIcon from '../../assets/cookiesIcon.png'
import Button from '../Button/Button'
import optionsStore from "../../zustand/optionsStore";
import assetsStore from "../../zustand/assetsStore";
import {useCookies} from 'react-cookie'
import {useNavigate, useLocation } from 'react-router-dom'
import {motion} from 'framer-motion'
import ReactGA from "react-ga4";

const Cookies = () => {
    const [isChecked, setIsChecked] = useState(true)
    const [onDocs, setOnDocs] = useState(true)
    const [cookies, setCookie] = useCookies(["acceptedCookie"]);
    const isMobile = optionsStore(state => state.isMobile)
    const txtItems = assetsStore(state => state.txtItems)
    let location = useLocation()
    let navigate = useNavigate()
    const handleClick = () => {
        let expireTime = new Date();
        
        if(isChecked){
            expireTime.setFullYear(expireTime.getFullYear() + 1)
        }else{
            expireTime.setDate(expireTime.getDate() + 1)
        }
        ReactGA.initialize(process.env.REACT_APP_GA_CODE);
        setCookie('acceptedCookie', "true", {
            path: "/",
            expires: expireTime
          })
    }

    useEffect(() => {
        if(location.pathname === "/terms"){
            setOnDocs(true)
        }else{
            setOnDocs(false)
        }
    }, [location])

    return(
        <>{onDocs ? <></> : <motion.div className="fixed left-0 top-0 w-screen h-screen flex
         justify-center items-center z-20 " style={{backgroundColor: 'rgba(0, 0, 0, 0.774)'}}
         initial={{opacity: 0}} animate={{opacity: 1}}>
            <div className="w-11/12 xs:w-[500px] h-[400px] flex flex-col justify-around
             items-center p-[2.5%] text-white">
            <img className="invert" src={cookiesIcon} alt="Cookies icon" />
            <div className="text-left w-full">
                <p className="text-center font-bold">{txtItems?.cookiesTitle}</p>
                <br/>
                <p>{txtItems?.cookiesDesc}</p>
                <br/>
                <div className="w-full flex justify-between items-center">
                    <div className="w-4/5">
                        <p>{txtItems?.cookiesBtn}</p>
                    </div>
                    <motion.div onClick={() => {setIsChecked(!isChecked)}}  className="w-16 h-8 
                    rounded-full p-1 cursor-pointer overflow-hidden bg-gray-300 relative">
                        <motion.div initial={{x: '100%'}} animate={{x: isChecked ? '100%' : 0}} transition={{type: 'linear', duration: 0.3, delay: 0}} 
                        className="absolute top-[2.5%] h-[95%] bg-white aspect-square rounded-full z-[21]"></motion.div>
                        <motion.div initial={{x: '-10%'}} animate={{x: isChecked ? '-10%' : '-110%'}} transition={{type: 'linear', duration: 0.3, delay: 0}}
                         className="absolute top-0 w-[120%] h-[120%] bg-primary"></motion.div>
                    </motion.div>
                </div>
            </div>
            <div className="w-full flex flex-col xs:flex-row  justify-around items-center mt-4 ">
                <Button onClick={handleClick}  text={txtItems?.accept} 
                style={{padding: '0 1rem', width: isMobile ? '100%' : '45%', height: '70px'}} />
                <Button onClick={() => navigate('/terms')} secondary text={txtItems?.learnMore} 
                style={{padding: '0 1rem', width: isMobile ? '100%' : '45%', height: '70px', marginTop: isMobile ? '1rem' : 0}} />
            </div>
        </div></motion.div>}</>
    )
}

export default Cookies