import React, {useState, useEffect} from "react";
import assetsStore from "../../zustand/assetsStore";
import {motion} from 'framer-motion'

const SupportButton = ({icon, support, value, onClick, active, formContent, setFormContent}) => {

    const [isHovering, setIsHovering] = useState(false)
    const txtItems = assetsStore(state => state.txtItems)

    const handleChangeInput = (e) => {
        if((e.target.value.slice(-1) === "z" || e.target.value.slice(-1) === "ł") && !isNaN(e.target.value.slice(-2, -1))){
            if(e.target.value.replace(/\D+/g, "").slice(0, -1) > 0){
                setFormContent({...formContent, amount: e.target.value.replace(/\D+/g, "").slice(0, -1)})
            }else{
                setFormContent({...formContent, amount: -1})
            }
        }else{
            if(e.target.value.replace(/\D+/g, "") > 0){
                setFormContent({...formContent, amount: e.target.value.replace(/\D+/g, "")})
            }else{
                setFormContent({...formContent, amount: -1})
            }
        }
    }

    const handleBlur = (e) => {
        if(formContent.amount === -1){
            setFormContent({...formContent, amount: 1})
        }
    }
  
    return(
        <div className="flex justify-center items-center w-[40%] xs:w-[26%] sm:w-1/5 mb-8 sm:mb-0 " >
            <div  onClick={!active ? onClick : undefined} className="w-full sm:w-36 aspect-square bg-white dark:bg-bgDarker
             transition cursor-pointer rounded-2xl shadow-md dark:shadow-2xl text-black 
            dark:text-white hover:bg-primarySoft dark:hover:bg-primarySoft 
            hover:text-white overflow-hidden" style={{backgroundColor: active ? "#E2494E" : undefined, color: active ? "#fff" : undefined}}
            onMouseEnter={() => setIsHovering(true)} onMouseLeave={() => setIsHovering(false)}>
                <motion.div className="w-full h-4/6 flex justify-center items-center"
                initial={{scale: 1}} animate={{scale: isHovering || active ? 1.4 : 1}}>
                    {formContent ? <> { active ? 
                    <div className="w-11/12 flex justify-center items-center">
                        <input type="text" min={5} value={`${formContent.amount !== -1 ? formContent.amount : ""}${txtItems?.['support-zl']}`} 
                        className="w-1/2 text-center bg-transparent outline-none" onChange={handleChangeInput} onBlur={handleBlur}/>
                    </div>
                    : <img className="max-w-[4.5rem] max-h-[4.5rem]" src={icon} alt={'support icon'} />
                    }</>: <img className="max-w-[4.5rem] max-h-[4.5rem]" src={icon} alt={'support icon'} />}
                </motion.div>
                <div className="h-2/6 flex justify-center items-center 
                 text-center text-lg font-bold">{support ? txtItems?.['support-volunteering'] : <>{!value ? txtItems?.['support-otherAmount'] : `${value} ${txtItems?.['support-zl']}`}</>}</div>
            </div>
        </div>
    )
}

export default SupportButton