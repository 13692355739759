import React, {useState, useEffect} from "react";
import Header from '../../components/Header/Header'
import assetsStore from "../../zustand/assetsStore";
import { motion } from "framer-motion";
import TeamItem from "./TeamItem/TeamItem";

const Team = () => {
    const txtItems = assetsStore(state => state.txtItems)
    const team = assetsStore(state => state.team)
    const currentLanguage = assetsStore(state => state.currentLanguage)

    const [filterItems, setFilterItems] = useState([])
    const [currentFilters, setCurrentFilters] = useState([])
    
    useEffect(() => {
        let items = []
        
        team.forEach(i => {
            if(!items.includes(i.desc[currentLanguage])){
                items.push(i.desc[currentLanguage])
            }
        })

        setFilterItems(items)
    }, [currentLanguage])
    
    const handleChangeFilter = (item) => {
        if(currentFilters.includes(item)){
            setCurrentFilters(currentFilters.filter(i => i !== item))
        }else{
            setCurrentFilters([...currentFilters, item])
        }
    }

    return (
        <div className='w-full h-full flex flex-col'>
            <Header title={txtItems?.['team-team']} elementId='Content' cta={txtItems?.['learnMore']} />
            <div id="Content" className="w-full md:w-smContent lg:w-content mb-8 m-auto px-4 flex flex-col dark:text-white"> 
                <p className="w-11/12 sm:w-3/4 md:w-2/3 mx-auto text-lg text-center">{txtItems?.['team-desc']}</p>
                <div className="w-11/12 md:w-2/3 sm:mx-auto mt-12 flex sm:justify-center flex-nowrap overflow-x-auto sm:overflow-x-hidden sm:flex-wrap ">
                    {filterItems?.map(item => (
                        <div onClick={() => handleChangeFilter(item)}
                        className="px-4 mt-12 py-2 rounded-full cursor-pointer border-2 border-solid whitespace-nowrap
                         border-black dark:border-bgBright transition-all text-sm mr-2 sm:mr-0 sm:mx-6 "
                         style={{borderColor: currentFilters.includes(item) ? '#E2494E' : undefined, color: currentFilters.includes(item) ? '#E2494E' : undefined}}>
                            {item}
                        </div>
                    ))}
                </div>
                <div className="w-full flex flex-wrap justify-center">
                    {currentFilters.length > 0 ? 
                        team?.filter(i => currentFilters.includes(i.desc[currentLanguage])).map((item, index) => <TeamItem item={item} index={index}/>)
                        : 
                        team?.map((item, index) => <TeamItem item={item} index={index}/>)
                    }
                </div>
            </div>
        </div>
  )
}

export default Team