import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import {Link, useNavigate, useLocation} from 'react-router-dom'

const MenuItem = ({text, page, section, support, setIsOptionsOpen}) => {
    const navigate = useNavigate()
    const location = useLocation()

    const handleClick = async () => {
        if(location.pathname !== page){
            await navigate(page)
        }
        setIsOptionsOpen(false)
        if(section !== "support"){
            document.getElementById(section).scrollIntoView({behavior: "smooth", block: "start"})
        }else{
            setTimeout(() => {
                document.getElementById(section).scrollIntoView({behavior: "smooth", block: "start"})

            }, 500)
        }
    }

    return (
        <>{!support ?
            <div className="w-11/12 h-16 border-b-gray-300 dark:border-b-gray-500  border-b-2 flex items-center justify-between px-4 text-sm dark:text-white" 
            onClick={handleClick}>
                <p className="text-lg ">{text}</p>
                <FontAwesomeIcon icon={faChevronRight} />                
            </div> :
            <div className="w-11/12 h-16 border-primary border-b-2 flex items-center justify-between px-4 text-sm  text-primary"
            onClick={handleClick}>
                <p className="text-lg ">{text}</p>
                <FontAwesomeIcon icon={faChevronRight} />
            </div>
    }</>
    )
}

export default MenuItem