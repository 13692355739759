export const aboutUsImg = {
    initial: {
        left: "calc(50% - 200px)",
        top: "30px",
        transition: {
            duration: 0.2,
            type: 'linear'
        }
    },
    animate: {
        left: "calc(50% - 245px)",
        top: "7px",
        transition: {
            duration: 0.2,
            type: 'linear'
        }
    }
}
export const chevron = {
    initial: {
        rotate: 0,
        transition: {
            duration: 0.35,
            type: 'linear'
        }
    },
    animate: {
        rotate: 180,
        transition: {
            duration: 0.35,
            type: 'linear'
        }
    }
}
export const faqItem = {
    initial: {
        y: '-100%',
        transition: {
            duration: 0.4,
            type: 'linear'
        }
    },
    animate: {
        y: 0,
        transition: {
            duration: 0.4,
            type: 'linear'
        }
    }
}

export const chapterList = {
    initial: {
        y: '-100%',
        transition: {
            duration: 0.4,
            type: 'linear'
        }
    },
    animate: {
        y: 0,
        transition: {
            duration: 0.4,
            type: 'linear'
        }
    }
}

export const coppiedInfo = {
    initial: {
        opacity: 0,
        transition: {
            duration: 0.4,
            type: 'linear'
        }
    },
    animate: {
        opacity: 1,
        transition: {
            duration: 0.4,
            type: 'linear'
        }
    }
}

export const supportContainer = {
    initial: {
        opacity: 0,
        scale: 0.6,
        transition: {
            // delay: 0.8,
            duration: 0.3,
            type: 'linear'
        }
    },
    animate: {
        opacity: 1,
        scale: 1,
        transition: {
            delay: 0.35,
            duration: 0.3,
            type: 'linear'
        }
    }
}
export const lineTop = {
    animate: {
        rotate: -45,
        y: '5px'
    },
    initial: {
        
    }
}
export const lineBottom = {
    animate: {
        rotate: 45,
        y: '-6px'
    },
    initial: {
        
    }
}
export const lineMiddle = {
    animate: {
        x: 200,
        display: 'none',

    },
    initial: {
        x: 0,
        display: 'flex',
        transition: { 
            duration: 0.4,
            ease: 'linear',
        }
    }
}
export const clear = {
    animate: {

    },
    initial: {

    }
}
export const menu = {
    initial: {
        left: '-100%',
        transition: { 
            duration: 0.3,
            ease: 'linear',
        }
    },
    animate: {
        left: 0,
        transition: { 
            duration: 0.3,
            ease: 'linear',
        }
    }
}
export const carouselLeft = {
    initial: {
        x: '100%',
        display: 'none',
        transition: { 
            delay: 0.1,
            duration: 0.4,
            ease: 'linear',
        }
    },
    animate: {
        x: 0,
        display: 'flex',
        transition: { 
            duration: 0.4,
            delay: 1,
            ease: 'linear',
        }
    },
    exit: {
        x: '-100%',
        transition: { 
            duration: 0.4,
            ease: 'linear',
        }
    },
}
export const carouselRight = {
    initial: {
        display: 'none',
        x: '-100%',
        transition: { 
            duration: 0.4,
            ease: 'linear',
        }
    },
    animate: {
        x: 0,
        display: 'flex',
        transition: { 
            duration: 0.4,
            delay: 1,
            ease: 'linear',
        }
    },
    exit: {
        x: '100%',
        transition: { 
            delay: 0.1,
            duration: 0.4,
            ease: 'linear',
        }
    },
}
