import create from 'zustand'
import * as api from '../api/index'
import { devtools } from 'zustand/middleware'


const store = (set, get) => ({
    txtItems: {},
    languages: [],
    currentLanguage: "",
    allTxtItems: [],
    newsItems: [],
    team: [],
    cooperation: [],
    gallery: [],
    press: [],
    isSiteBlocked: false, 
    isSiteLoaded: false,
    fundationIncome: 0,
    numberOfFundraisings: 0,
    statut: [],
    rodo: [],
    loadAssets: async () => {
        try{
           const isSiteBLockedRes = await api.getIsSiteBlocked()
           if(!isSiteBLockedRes.data.isBlocked){
                //site is not blocked
                await set((state) => ({isSiteBlocked: false}))

                //load txtItems
                const languagesRes = await api.getLanguages()
                await set((state) => ({languages: languagesRes?.data?.result.filter(i => i.isPublic && i)}))
            
                const languagesSettingsRes = await api.getLanguagesSettings()
                let langId = ""
                switch (navigator.language.slice(0, 2)) {
                    case "pl":
                        langId = languagesSettingsRes?.data?.result.filter(i => i.name === "Polski")[0].language
                        break;
                    case "uk":
                        langId = languagesSettingsRes?.data?.result.filter(i => i.name === "Ukrainy")[0].language
                        break;
                    default: 
                        langId = languagesSettingsRes?.data?.result.filter(i => i.name === "Wszystkich innych")[0].language
                        break;
                }
                await set((state) => ({currentLanguage: langId}))

                const txtItemsRes = await api.getTxtItems()
                await set((state) => ({allTxtItems: txtItemsRes?.data?.result}))

                let txtItemsObj = {}
                await txtItemsRes?.data?.result.forEach(i => {
                    if(i.languages[langId]?.length > 0){
                        txtItemsObj[i.itemId] = i.languages[langId]
                    }else{
                        txtItemsObj[i.itemId] = i.itemTxt
                    }
                })

                await set((state) => ({txtItems: txtItemsObj}))


                //load incomes
                const icomesRes = await api.getIncomes()

                let wholeAmount = 0
                let numberOfFundraisings = 0
                await icomesRes?.data?.moneyChart.forEach(i => {
                    wholeAmount = wholeAmount + i.amount
                    if(i.type === "zrzutka"){
                        numberOfFundraisings++
                    }
                })
                await set((state) => ({fundationIncome: wholeAmount, numberOfFundraisings: numberOfFundraisings}))


                //load news items
                const newsRes = await api.getNews()
                let newsItems = newsRes?.data?.result.filter(i => i.isPublic === true)
                await set((state) => ({newsItems: newsItems}))


                //load cms items
                const cmsRes = await api.getCmsItems()
                await cmsRes?.data?.result.forEach(i => {
                    switch (i.type) {
                        case "Zespół":
                            set((state) => ({team: [...state.team, i]}))
                            break;
                        case "Współprace":
                            set((state) => ({cooperation: [...state.cooperation, i]}))
                            break;
                        case "Galeria":
                            set((state) => ({gallery: [...state.gallery, i]}))
                            break;
                        case "Prasa":
                            set((state) => ({press: [...state.press, i]}))
                            break;
                    }
                })

                //load documents 
                const docsRes = await api.getDocsItems()
                await docsRes?.data?.result.forEach(i => {
                    switch (i.type) {
                        case "Rodo":
                            set((state) => ({rodo: [...state.rodo, i]}))
                            break;
                        case "Statut":
                            set((state) => ({statut: [...state.statut, i]}))
                            break;
                    }
                })

           }else{
               await set((state) => ({isSiteBlocked: true}))
           }
       }catch(err){
            console.log(err)
           await set((state) => ({isSiteBlocked: true}))
       }
       await set((state) => ({isSiteLoaded: true}))
   },
   checkLoginLink: async (code, navigate) => {
        try{
           let body = [code]
           const result = await api.checkLoginLink(body)
           if(result.data.message === 'OK!'){
               await set((state) => ({isSiteBlocked: false}))
               navigate('/home')
           }
       }catch(err){
           await set((state) => ({isSiteBlocked: true}))
           console.log(err)
       }
       await set((state) => ({isSiteLoaded: true}))
   },

    changeLanguage: async (newLang) => {
        await set((state) => ({currentLanguage: newLang}))
        const txtItemsRes = await get().allTxtItems

        let txtItemsObj = {}
        await txtItemsRes.forEach(i => {
            if(i.languages[newLang]?.length > 0){
                txtItemsObj[i.itemId] = i.languages[newLang]
            }else{
                txtItemsObj[i.itemId] = i.itemTxt
            }
        })

        await set((state) => ({txtItems: txtItemsObj}))
    },
})


let assetsStore

if(process.env.PRODUCTION === "true"){
    assetsStore = create(store)
}else{
    assetsStore = create(devtools(store))
}

export default assetsStore