import React, {useState, useEffect} from "react";
import Header from '../../components/Header/Header'
import assetsStore from "../../zustand/assetsStore";
import optionsStore from "../../zustand/optionsStore";
import messagesStore from "../../zustand/messagesStore";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationDot, faAddressCard, faEnvelope, faCircleDot, faChevronDown} from '@fortawesome/free-solid-svg-icons';
import Button from '../../components/Button/Button'
import Input from '../../components/Input/Input'
import { motion } from "framer-motion";

const Contact = () => {
    const txtItems = assetsStore(state => state.txtItems)
    const isMobile = optionsStore(state => state.isMobile)
    const sendMessage = messagesStore(state => state.sendMessage)

    const [isSelectingSubject, setIsSelectingSubject] = useState(false)
    const [type, setType] = useState("mail")
    const [formContent, setFormContent] = useState({
        name: "",
        lastName: "",
        email: "",
        message: "",
        amount: 25,
        terms: false,
    })
    const [errorMsg, setErrorMsg] = useState("")

    useEffect(() => {
        setErrorMsg("")
    }, [formContent])

    
    const handleSubmit = async (e) => {
        e.preventDefault()
        if(!isSelectingSubject){
            await sendMessage(type, formContent)
            await setFormContent({
                name: "",
                email: "",
                message: "",
            })
            await setErrorMsg(txtItems?.['contact-send'])
        }else{
            setErrorMsg(txtItems?.['contact-subject'])
        }

    }

    const handleChange = (e) => {
        setFormContent({...formContent, [e.target.name]: e.target.value})
    }

    return (
        <div className='w-full h-full flex flex-col'>
            <Header title={txtItems?.['nav-contact']} elementId='Content' cta={txtItems?.['learnMore']} />
            <div id="Content" className="w-full md:w-smContent lg:w-content mb-8 m-auto px-4 flex flex-col sm:flex-row dark:text-white"> 
                <div className='w-full sm:w-1/2 h-full flex flex-col '>
                    <h2 className='text-3xl font-bold'>{txtItems?.['contact-title']}</h2>
                    <h2 className='text-3xl font-bold'>{txtItems?.['contact-name']}</h2>
                    <h3 className='text-xl mt-8'>{txtItems?.['contact-data']}</h3>
                    <p className='text-sm mt-2'><FontAwesomeIcon icon={faAddressCard}/>{txtItems?.['contact-krs']}</p>
                    <p className='text-sm'><FontAwesomeIcon icon={faAddressCard}/>{txtItems?.['contact-regon']}</p>
                    <p className='text-sm'><FontAwesomeIcon icon={faAddressCard}/>{txtItems?.['contact-nip']}</p>
                    <h3 className='text-xl mt-6'>{txtItems?.['contact-contact']}</h3>
                    <p className='text-sm mt-2'><FontAwesomeIcon icon={faEnvelope}/>{txtItems?.['contact-mail']}</p>
                    <p className='text-sm'><FontAwesomeIcon icon={faEnvelope}/>{txtItems?.['contact-volunteering']}</p>
                    <h3 className='text-xl mt-6'>{txtItems?.['contact-addresses']}</h3>
                    <p className='text-sm mt-2'><FontAwesomeIcon icon={faLocationDot}/>{txtItems?.['contact-fundation-address']}</p>
                    <p className='text-sm'><FontAwesomeIcon icon={faLocationDot}/>{txtItems?.['contact-ignitorium-address']}</p>
                    <h3 className='text-xl mt-6'>{txtItems?.['contact-transfer']}</h3>
                    <p className='text-sm mt-2'><FontAwesomeIcon icon={faCircleDot}/>{txtItems?.['contact-bank-number']}</p>
                    <p className='text-sm'><FontAwesomeIcon icon={faCircleDot}/>{txtItems?.['contact-bank-name']}</p>
                    <p className='text-sm'><FontAwesomeIcon icon={faCircleDot}/>{txtItems?.['contact-bank-swift']}</p>
                    <p className='text-sm'><FontAwesomeIcon icon={faCircleDot}/>{txtItems?.['contact-bank-paypal']}</p>
                </div>
                <form className='w-full sm:w-1/2 mt-8 sm:mt-0 h-full flex flex-col ' onSubmit={handleSubmit}>
                    <motion.div className="border-2 border-solid border-gray-400  text-lg transition relative overflow-hidden
                    outline-none cursor-pointer rounded-2xl  bg-transparent invalid:bg-transparent valid:bg-transparent dark:text-white
                    hover:border-primary focus:border-primary "  style={{borderColor: !isSelectingSubject ? "rgb(227 30 36)" : 'rgb(156 163 175)'}}
                    initial={{height: '60px'}} animate={{height: isSelectingSubject ? "180px" : '60px'}}>
                        <div className="w-full flex px-2 sm:px-7 h-[60px] justify-between items-center " onClick={() => setIsSelectingSubject(!isSelectingSubject)}>
                            <p>{isSelectingSubject ? txtItems?.['contact-form-pick'] : txtItems?.[`contact-form-${type}`]}</p>    
                            <motion.span initial={{rotate: '0deg'}} animate={{rotate: isSelectingSubject ? '180deg' : '0deg'}}><FontAwesomeIcon icon={faChevronDown} /></motion.span>
                        </div>  
                        <motion.div className="w-full flex flex-col">
                            <div className="w-full flex px-2 sm:px-7 h-[60px] justify-between items-center hover:bg-gray-300 dark:hover:bg-gray-700 " 
                            onClick={() => {setType('mail'); setIsSelectingSubject(false)}}>
                                <p>{txtItems?.['contact-form-mail']}</p>    
                            </div>    
                            <div className="w-full flex px-2 sm:px-7 h-[60px] justify-between items-center hover:bg-gray-300 dark:hover:bg-gray-700"
                             onClick={() => {setType('volunteer'); setIsSelectingSubject(false)}}>
                                <p>{txtItems?.['contact-form-volunteer']}</p>    
                            </div>  
                        </motion.div>    
                    </motion.div>
                    <Input required maxLength={100} value={formContent.name} onChange={handleChange} name="name" 
                    style={{width: '100%', marginTop: '1rem'}} placeholder={txtItems?.['name']}/>
                    <Input type="email" required maxLength={100} value={formContent.email} onChange={handleChange} name="email"
                     style={{width: '100%', marginTop: '1rem'}} placeholder={txtItems?.['mail']}/>
                     <Input required maxLength={200} value={formContent.message} onChange={handleChange} name="message" textarea style={{width: '100%', marginTop: '1rem', height: '30vh', resize: "none"}}
                    placeholder={type === "mail" ? txtItems?.['support-msg'] : txtItems?.['support-volunteering-msg']}/>
                    <div className="w-full mt-8 sm:mt-12 flex justify-center">
                        <Button submit style={{width: isMobile ? '92%' : '50%'}} text={txtItems?.['send']}/>
                    </div>
                    <div className="w-full text-center h-8 mt-4 text-primarySoft overflow-hidden">
                        {errorMsg.length > 0 &&
                            <motion.span initial={{opacity: 0, y: 40}} animate={{opacity: 1, y: 0}} exit={{opacity: 0, y: 40}}>
                                {errorMsg}
                            </motion.span>
                        }
                    </div>
                </form>
            </div>
        </div>
  )
}

export default Contact