import React, {useState, useEffect} from "react";
import galleryImg from '../../../../assets/gallery1.jpg'
import {motion} from 'framer-motion'
import ImageSchowcase from '../../../../components/ImageShowcase/ImageSchowcase'
import optionsStore from "../../../../zustand/optionsStore";
import assetsStore from "../../../../zustand/assetsStore";


const SuccesesItem = ({item, index}) => {
  const [isShowcaeOpen, setIsShowcaseOpen] = useState(false)
  const windowSize = optionsStore(state => state.windowSize)
  const currentLanguage = assetsStore(state => state.currentLanguage)

  return (
    <>
    <motion.div key={item._id} initial={{x: 20, y: 20, opacity: 0}} animate={{x: 0, y: 0, opacity: 1, transition: {delay: index * .2}}} exit={{x: 20, y: 20, opacity: 0}} 
     className=" w-[47%] md:w-[30%] mb-[1.5%] mr-[3%] h-[30%] rounded-xl overflow-hidden  shadow-xl  cursor-pointer bg-cover bg-center" onClick={() => setIsShowcaseOpen(true)} 
     style={{backgroundImage: `url(${item.img})`}}>
      {/* <img className='w-full h-full hover:scale-110 transition-all ' src={item} /> */}
    </motion.div>
    {isShowcaeOpen && <ImageSchowcase img={item.img} title={item.desc[currentLanguage]} setIsShowcaseOpen={setIsShowcaseOpen}/> }
    </>
  )
}

export default SuccesesItem