import React, {useState, useEffect} from "react";
import assetsStore from "../../../zustand/assetsStore";
import optionsStore from "../../../zustand/optionsStore";
import PressItem from "./PressItem/PressItem";
import {motion} from 'framer-motion'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight, faChevronLeft, faPlus, faMinus } from '@fortawesome/free-solid-svg-icons'

const Press = () => {
    const txtItems = assetsStore(state => state.txtItems)
    const currentLanguage = assetsStore(state => state.currentLanguage)
    const press = assetsStore(state => state.press)
    const windowSize = optionsStore(state => state.windowSize)
    const [currentCol, setCurrentCol] = useState(0)
    const [minNumOfCols, setMinNumOfCols] = useState(3)
    const [isOpen, setIsOpen] = useState(false)

    useEffect(() => {
        if(windowSize > 1100 ) {
            setMinNumOfCols(3)
        }else{
            setMinNumOfCols(2)
        }
    }, [windowSize])

    return (
        <div id="press" className='w-11/12 md:w-smContent lg:w-content m-auto mt-0 sm:mt-24 md:mt-36 lg:mt-48 mb-24  dark:text-white '>
            <div className="w-full h-full flex items-center justify-between sm:justify-start " onClick={() => windowSize < 700 && setIsOpen(!isOpen)}>
                <h2 className='text-2xl text-primarySoft'>{txtItems?.['press-title']}</h2>
                {windowSize < 700 && <div className="h-full aspect-square flex justify-center items-center text-primarySoft text-2xl">
                <FontAwesomeIcon icon={isOpen ? faMinus : faPlus} />
                </div>}
            </div>
            <motion.div animate={windowSize < 700 ? {maxHeight: isOpen ? '9999px' : '0px', transition: {duration: 1.4}} : {maxHeight: '450px'}}
             className="w-full h-[450px] flex  justify-around sm:mt-12 relative overflow-hidden">
                <motion.div animate={{left: windowSize > 1100 ? `-${currentCol * 33.333}%` : `-${ windowSize > 700 ? currentCol * 50 : currentCol * 100}%`}}
                 className="w-full h-full flex flex-row items-center absolute top-0 left-0">
                    {press.map(item => <PressItem item={item} />)}
                </motion.div>
                {press?.length > minNumOfCols && <>
                    <motion.div onClick={() => currentCol > 0 ? setCurrentCol(currentCol - 1) : undefined}
                    className="w-10 h-full  absolute left-0 top-0 flex items-center justify-center z-[2]">
                        <motion.div animate={{opacity: currentCol > 0 ? 1 : 0}}
                        className="w-full bg-black text-white dark:bg-white dark:text-black cursor-pointer aspect-square flex justify-center items-center">
                            <FontAwesomeIcon icon={faChevronLeft} />
                        </motion.div>
                    </motion.div>
                    {windowSize > 700 ? <>{windowSize > 1100 ? <motion.div onClick={() => currentCol + 3 < press?.length ? setCurrentCol(currentCol + 1) : undefined}
                    className="w-10 h-full  absolute right-0 top-0 flex items-center justify-center z-[2]">            
                        <motion.div animate={{opacity: currentCol + 3 < press?.length ? 1 : 0}}
                        className="w-full bg-black text-white dark:bg-white dark:text-black cursor-pointer aspect-square flex justify-center items-center">
                            <FontAwesomeIcon icon={faChevronRight} />
                        </motion.div> 
                    </motion.div> :
                    <motion.div onClick={() => currentCol + 2 < press?.length ? setCurrentCol(currentCol + 1) : undefined}
                    className="w-10 h-full  absolute right-0 top-0 flex items-center justify-center z-[2]">                   
                        <motion.div animate={{opacity: currentCol + 2 < press?.length ? 1 : 0}}
                        className="w-full bg-black text-white dark:bg-white dark:text-black cursor-pointer aspect-square flex justify-center items-center">
                            <FontAwesomeIcon icon={faChevronRight} />
                        </motion.div>
                    </motion.div>}</> : 
                    <motion.div onClick={() => currentCol + 1 < press?.length ? setCurrentCol(currentCol + 1) : undefined}
                    className="w-10 h-full  absolute right-0 top-0 flex items-center justify-center z-[2]">                   
                        <motion.div animate={{opacity: currentCol + 1 < press?.length ? 1 : 0}}
                        className="w-full bg-black text-white dark:bg-white dark:text-black cursor-pointer aspect-square flex justify-center items-center">
                            <FontAwesomeIcon icon={faChevronRight} />
                        </motion.div>
                    </motion.div>}
                </>}
            </motion.div>
        </div>
    )
}

export default Press