import React from "react";
import optionsStore from "../../zustand/optionsStore";

const Footer = () => {
    const isMobile = optionsStore(state => state.isMobile)
    return(
        <div className="w-full p-4 md:px-0">
            <div className="w-full md:w-smContent lg:w-content m-auto flex flex-row justify-center sm:justify-between">
                {!isMobile && <a href="https://www.linkedin.com/in/mikołaj-lewicki-5975011b8/" target="_blank">
                    <div className="text-center text-sm text-gray-300 no-underline cursor-pointer">
                        <p>{'< Made with <3'}</p>
                        <p>{'by Mikołaj Lewicki />'}</p>
                    </div>
                </a>}
                <a href="https://goo.gl/maps/tKNHxpvkBvcMZ4CF7" target="_blank">
                    <div className="text-center text-sm text-gray-300 no-underline cursor-pointer">
                        <p>fundacja@corignis.org</p>
                        <p>ul. Przemysłowa 48 Piotrków Trybunalski</p>
                    </div>
                </a>
            </div>
        </div>

    )
}

export default Footer