import React, {useState, useEffect} from "react";
import assetsStore from "../../../zustand/assetsStore";
import optionsStore from "../../../zustand/optionsStore";
import SvgMap from "./SvgMap/SvgMap";
import SuccesesItem from "./SuccesesItem/SuccesesItem";
import { AnimatePresence, motion } from "framer-motion";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight, faChevronLeft, faPlus, faMinus } from '@fortawesome/free-solid-svg-icons'


const Succeses = () => {
    const txtItems = assetsStore(state => state.txtItems)
    const gallery = assetsStore(state => state.gallery)
    const windowSize = optionsStore(state => state.windowSize)
    const isMobile = optionsStore(state => state.isMobile)
    const [currentItem, setCurrentItem] = useState("all")
    const [numOfCols, setNumOfCols] = useState(0)
    const [currentCol, setCurrentCol] = useState(0)
    const [minNumOfCols, setMinNumOfCols] = useState(9)
    const [isOpen, setIsOpen] = useState(false)
    const [items, setItems] = useState({})
    

    useEffect(() => {
        let newItems = {}
        gallery.forEach(i => {
            if(newItems[i.country.value]){
                newItems[i.country.value].push(i)
            }else{
                newItems[i.country.value] = [i]
            }
            
        })
        
        Object.keys(newItems).forEach(i => {
            newItems[i].sort((a,b) => a.order - b.order)
        })
        setItems(newItems)
        if(currentItem !== "all"){
            if(newItems[currentItem]?.length % 3 === 0) {
                setNumOfCols(newItems[currentItem]?.length / 3)

            }else{
                setNumOfCols(Math.floor(newItems[currentItem]?.length / 3) + 1)
            }
        }else{
            if(gallery?.length % 3 === 0) {
                setNumOfCols(gallery?.length / 3)

            }else{
                setNumOfCols(Math.floor(gallery?.length / 3) + 1)
            }

        }
    }, [currentItem])

    useEffect(() => {
        if(windowSize > 1100 ) {
            setMinNumOfCols(3)
        }else{
            setMinNumOfCols(2)
        }
    }, [windowSize])

    useEffect(() => {
        setCurrentCol(0)
    }, [currentItem])


    return (
    <div id="succeses" className="w-11/12  md:w-smContent lg:w-content mx-auto mt-4 sm:mt-24 md:mt-36 lg:mt-48 dark:text-white  flex flex-col sm:flex-row justify-between items-center z-[3]">
        {!isMobile ? <div className="w-full sm:w-1/3  overflow-x-hidden h-full pr-4">
            <SvgMap currentItem={currentItem} setCurrentItem={setCurrentItem} items={items}/>
        </div> : 
        <div className="w-full h-auto  flex items-center justify-between sm:justify-start " onClick={() => isMobile && setIsOpen(!isOpen)}>
            <h2 className="text-2xl text-primarySoft md:text-primary md:text-4xl md:mb-4">{txtItems?.['succeses-title']}</h2>
            <div className="h-full aspect-square flex justify-center items-center text-primarySoft text-2xl">
            <FontAwesomeIcon icon={isOpen ? faMinus : faPlus} />
            </div>
        </div>}
        <motion.div className="w-full sm:w-3/5 lg:w-1/2 h-full flex flex-col items-center mt-4 " 
        animate={isMobile ? {maxHeight: isOpen ? '9999px' : '0px', transition: {duration: 1.4}} : {maxHeight: '100%'}}>
            {!isMobile ? <h2 className="text-2xl text-primarySoft md:text-primary md:text-4xl md:mb-4">{txtItems?.['succeses-title']}</h2> :
            <div className="w-4/5 xs:w-2/3 overflow-x-hidden">
                <SvgMap currentItem={currentItem} setCurrentItem={setCurrentItem} items={items}/>
            </div> 
            }
            <div className="mt-12 sm:mt-0 w-full h-[450px] relative">
                <div className="w-full h-full overflow-hidden relative">
                    <motion.div animate={{left: windowSize > 1100 ? `-${currentCol * 33}%` : `-${currentCol * 50}%`}}
                    className="w-full h-full flex flex-col content-start flex-wrap absolute top-0">
                    <AnimatePresence>
                        {currentItem === "all" ? gallery.sort((a, b) => a.order - b.order).map((item, index) => <SuccesesItem item={item} index={index}/> ): 
                        items[currentItem]?.map((item, index) => <SuccesesItem item={item} index={index}/>)}
                    </AnimatePresence>
                    </motion.div>
                </div>
                {(currentItem !== "all" ? items[currentItem]?.length : gallery.length) > minNumOfCols && <>
                    {windowSize > 1100 ? <><motion.div 
                    onClick={() => currentCol > 0 ? setCurrentCol(currentCol - 1) : undefined}
                    whileHover={{backgroundColor: currentCol > 0 ? "rgba(0, 0, 0, 0.45)" : undefined}} 
                    animate={{color: currentCol > 0 ? '#fff' : '#616161'}}
                    className="w-6 h-full cursor-pointer  absolute left-0 top-0 flex items-center justify-center z-[2]">
                        <FontAwesomeIcon icon={faChevronLeft} />
                    </motion.div>
                     <motion.div
                     onClick={() => currentCol + 3 >= numOfCols ? undefined : setCurrentCol(currentCol + 1) }
                     whileHover={{backgroundColor: currentCol + 3 >= numOfCols ?  undefined : "rgba(0, 0, 0, 0.45)"}} 
                     animate={{color: currentCol + 3 >= numOfCols ? '#616161' : '#fff'}}
                     className="w-6 h-full cursor-pointer  absolute right-0 top-0 flex items-center justify-center z-[2]">
                        <FontAwesomeIcon icon={faChevronRight} />
                    </motion.div> </>: <>{isOpen && <>
                    <motion.div 
                    onClick={() => currentCol > 0 ? setCurrentCol(currentCol - 1) : undefined}
                    whileHover={{backgroundColor: currentCol > 0 ? "rgba(0, 0, 0, 0.45)" : undefined}} 
                    animate={{color: currentCol > 0 ? '#fff' : '#616161'}}
                    className="w-6 h-full cursor-pointer  absolute left-0 top-0 flex items-center justify-center z-[2]">
                        <FontAwesomeIcon icon={faChevronLeft} />
                    </motion.div>
                    <motion.div
                     onClick={() => currentCol + 2 >= numOfCols ? undefined : setCurrentCol(currentCol + 1) }
                     whileHover={{backgroundColor: currentCol + 2 >= numOfCols ?  undefined : "rgba(0, 0, 0, 0.45)"}} 
                     animate={{color: currentCol + 2 >= numOfCols ? '#616161' : '#fff'}}
                     className="w-6 h-full cursor-pointer  absolute right-0 top-0 flex items-center justify-center z-[2]">
                        <FontAwesomeIcon icon={faChevronRight} />
                    </motion.div> </>}</>}
                </>}
            </div>
        </motion.div>

    </div>
    )
}

export default Succeses