import React, {useState, useEffect, useRef} from "react";
import assetsStore from "../../../zustand/assetsStore";
import optionsStore from "../../../zustand/optionsStore";
import BrandsItem from "./BrandsItem/BrandsItem";
import {motion} from 'framer-motion'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons'

const Brands = () => {
    const txtItems = assetsStore(state => state.txtItems)
    const cooperation = assetsStore(state => state.cooperation)
    const currentLanguage = assetsStore(state => state.currentLanguage) 
    const isMobile = optionsStore(state => state.isMobile)
    const [isOpen, setIsOpen] = useState(false)

    return (
        <div id="bands" className='w-full sm:h-[350px]  mt-0 sm:mt-48 flex items-center flex-col sm:flex-row  sm:relative '>
            <div className="sm:absolute w-full z-[2]  top-0 ">
                <div className="w-11/12 md:w-smContent lg:w-content justify-between items-center sm:justify-start  flex mx-auto"
                onClick={() => isMobile && setIsOpen(!isOpen)}>
                    <h2 className='text-2xl text-primarySoft'>{txtItems?.['brands-title']}</h2>
                    {isMobile && <div className="h-full aspect-square flex justify-center items-center text-primarySoft text-2xl">
                    <FontAwesomeIcon icon={isOpen ? faMinus : faPlus} />
                    </div>}
                </div>
            </div>
            {!isMobile ? 
            <div  className="w-[130%] h-[200px] absolute left-[-10%] pl-[10%] pr-[20%] bg-white dark:bg-bgDarker rotate-[-3deg] ">
                <div className="w-full h-full relative" >
                    <div className="w-full h-full flex justify-start items-center absolute
                    animate-[slideout_15s_linear_infinite]" style={{width: `${cooperation?.length * 332}px`}}>
                    {cooperation?.map(item => (<BrandsItem item={item}/>))}
                    </div>
                    <div className="w-full h-full flex justify-start items-center absolute 
                    animate-[slidein_30s_linear_infinite]" style={{width: `${cooperation?.length * 332}px`}}>
                    {cooperation?.map(item => (<BrandsItem item={item}/>))}
                    </div>
                    <div className="w-full h-full flex justify-start items-center absolute 
                    animate-[slidein_30s_15s_linear_infinite] translate-x-[-100%]" style={{width: `${cooperation?.length * 332}px`}}>
                    {cooperation?.map(item => (<BrandsItem item={item}/>))}
                    </div>
                </div>
            </div>
             : 
            <motion.div animate={{maxHeight: isOpen ? '9999px' : '0px', transition: {duration: 1.4}}}
            className="overflow-hidden w-11/12 mt-4">
                <div className="w-full overflow-x-auto flex flex-col flex-wrap h-[250px] ">
                    {cooperation?.map(item => (<BrandsItem item={item}/>))}
                    {cooperation?.map(item => (<BrandsItem item={{...item, order: item.order + cooperation?.length}}/>))}
                </div>
            </motion.div> }
        </div>
    )
}

export default Brands