import React, {useEffect, useState} from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import MainView from "./views/MainView/MainView";
import optionsStore from "./zustand/optionsStore";
import assetsStore from "./zustand/assetsStore"
import Nav from "./components/Nav/Nav";
import Footer from "./components/Footer/Footer";
import WorkInProgress from "./views/WorkInProgress/WorkInProgress";
import Cookies from "./components/Cookies/Cookies";
import {useCookies} from 'react-cookie'
import ReactGA from "react-ga4";
import Loading from "./components/Loading/Loading";
import Terms from "./views/Terms/Terms";
import Contact from "./views/Contact/Contact";
import Blog from "./views/Blog/Blog";
import Team from "./views/Team/Team";

const App = () => {
    const checkDarkMode = optionsStore((state) => state.checkDarkMode)
    const checkWindowSize = optionsStore((state) => state.checkWindowSize)
    const checkDaysOfIgnitorium = optionsStore((state) => state.checkDaysOfIgnitorium)
    const checkTab = optionsStore((state) => state.checkTab)
    const darkMode = optionsStore((state) => state.darkMode)
    const loadAssets = assetsStore(state => state.loadAssets)
    const isSiteLoaded = assetsStore(state => state.isSiteLoaded)
    const isSiteBlocked = assetsStore(state => state.isSiteBlocked)
    const [cookies] = useCookies(["acceptedCookie"]);
    

    useEffect(() => {
        loadAssets()
        checkDarkMode()
        checkWindowSize()
        checkDaysOfIgnitorium()
        window.addEventListener('resize', checkWindowSize)
        document.addEventListener("visibilitychange", checkTab)
        if(cookies.acceptedCookie === 'true'){
            ReactGA.initialize(process.env.REACT_APP_GA_CODE);
        }
    }, [])

    return(
        <BrowserRouter>
            <div className={darkMode ? 'dark' : undefined} style={{backgroundColor: darkMode ?  '#2D2929' : '#f5f5f5', minHeight: '100vh'}}>
                {isSiteLoaded ? <>
                {!isSiteBlocked && <Nav />}
                <Routes>
                    <Route path="*" element={<Navigate to={isSiteBlocked ? `/workInProgress` : `/home`} /> } />
                    {!isSiteBlocked && <Route path="/home" element={<MainView />} />}
                    {!isSiteBlocked && <Route path="/contact" element={<Contact />} />}
                    {!isSiteBlocked && <Route path="/terms" element={<Terms />} />}
                    {!isSiteBlocked && <Route path="/team" element={<Team />} />}
                    {!isSiteBlocked && <Route path="/blog/*" element={<Blog />} />}
                    {isSiteBlocked &&<Route path="/workInProgress" element={<WorkInProgress />} />}
                </Routes>
                <Footer />
                {cookies.acceptedCookie !== 'true' && <Cookies />}
                </> :<Loading />}
            </div>
        </BrowserRouter>
    )
}
export default App