import React, {useState, useEffect} from "react";
import assetsStore from "../../../zustand/assetsStore";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChain } from '@fortawesome/free-solid-svg-icons'
import {motion} from 'framer-motion'

const TeamItem = ({item, index}) => {
    const txtItems = assetsStore(state => state.txtItems)
    const currentLanguage = assetsStore(state => state.currentLanguage)

    const [isHover, setIsHover] = useState(false)

    return (
        <motion.div key={item._id} initial={{ y: 100, opacity: 0}} animate={{x: 0, y: 0, opacity: 1, transition: {delay: index * .1, duration: .2}}}
        className="w-[97%] xs:w-[47%] sm:w-[30%] md:w-[22.5%] h-[450px] mx-[1.5%] sm:mx-0 sm:mr-[1%] md:mr-[2.5%] transition hover:shadow-2xl  mt-12 rounded-xl flex flex-col" style={{order: item.order}}
        onMouseEnter={() => setIsHover(true)} onMouseLeave={() => setIsHover(false)}>
            <motion.div initial={{borderWidth: 0}} animate={{borderWidth: isHover ? 1 : 0}}
            className="w-full h-4/5 transition-all  border-primarySoft   border-solid rounded-xl bg-top bg-cover"
             style={{backgroundImage: `url(${item.img})`}}></motion.div>
            <div className="w-full h-1/5 flex p-4">
                <div className="w-4/5 h-full flex flex-col items-start justify-around">
                    <p className="font-bold">{item.name[currentLanguage]}</p>
                    <p className="text-sm">{item.desc[currentLanguage]}</p>
                </div>
                {item.link !== "Brak linku" && <div className="w-1/5 h-full flex justify-end items-center text-2xl cursor-pointer" onClick={() => window.open(item.link, "_blank")}>
                    <FontAwesomeIcon icon={faChain} />
                </div>}
            </div>
        </motion.div>
    )
}

export default TeamItem