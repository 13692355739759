import React from "react";

const Button = ({text, secondary, style, submit, onClick}) => {
    return(
        <button onClick={onClick} 
        style={style} 
        className={secondary ? 
        "border-2 border-solid border-primary py-2 sm:py-4 px-4 sm:px-8 text-xl text-primary bg-transparent transition relative overflow-hidden cursor-pointer text-center rounded-lg z-[2] hover:bg-primary dark:hover:text-black hover:text-white" : 
        "border-2 border-solid border-primarySoft py-2 sm:py-4 px-4 sm:px-8 text-xl text-white bg-primarySoft transition relative overflow-hidden cursor-pointer text-center rounded-lg z-[2] hover:bg-primarySoft hover:border-primarySoft"}
        type={submit ? "submit" : "button"}>
            {text}
        </button>
    )
}

export default Button