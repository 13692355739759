import React, {useState, useEffect} from "react";
import optionsStore from "../../zustand/optionsStore";
   
const Input = ({placeholder, type, style, value, onChange, required, name, textarea, maxLength, min, max, disabled}) => {
    return(
        <>
            {textarea ? 
                <textarea name={name} 
                required={required} 
                value={value} 
                onChange={(e) => onChange(e)} 
                style={{borderColor: value?.length > 0 ? '#E2494E' : undefined, ...style}} 
                type={type ? type : "text"} 
                className="border-2 border-solid border-gray-400 px-7 py-4 text-lg transition relative overflow-hidden
                outline-none cursor-pointer resize-y max-h-[30vh] rounded-2xl  bg-transparent invalid:bg-transparent valid:bg-transparent dark:text-white
                hover:border-primary focus:border-primary"
                placeholder={placeholder} 
                disabled={disabled}
                maxLength={maxLength} />
                :
                <input name={name} 
                required={required} 
                value={value} 
                onChange={(e) => onChange(e)} 
                style={{borderColor: value?.length > 0 ? '#E2494E' : undefined, ...style}} 
                type={type ? type : "text"} 
                className="border-2 border-solid border-gray-400 px-7 py-4 text-lg transition relative overflow-hidden
                outline-none cursor-pointer resize-y max-h-[30vh] rounded-2xl bg-transparent invalid:bg-transparent valid:bg-transparent dark:text-white
                hover:border-primary focus:border-primary"
                placeholder={placeholder}
                disabled={disabled}
                maxLength={maxLength}
                min={min}
                max={max} />
            }
        </>
        
        )
}

export default Input