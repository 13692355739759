import axios from 'axios'

let API
if(process.env.REACT_APP_PRODUCTION === "true"){
    API = axios.create({baseURL: process.env.REACT_APP_API_LINK})
}else{
    API = axios.create({baseURL: 'http://localhost:5010'})
}

//sending message
export const sendMessage = (body) => API.post('/messages/sendMessage', body)

//verifying user
export const checkLoginLink = (body) => API.post('/admin/verifySiteLoginLink', body)

//loading assets
export const getIsSiteBlocked = () => API.get('/admin/getIsSiteBlocked')
export const getLanguages = () => API.get('/admin/getLanguages')
export const getLanguagesSettings = () => API.get('/admin/getLanguagesSettings')
export const getTxtItems = () => API.get('/admin/getTxtItems')
export const getIncomes = () => API.get('/admin/getMoneyChartData')
export const getNews = () => API.get('/admin/getNewsItem')
export const getCmsItems = () => API.get('/admin/getCmsItems')
export const getDocsItems = () => API.get('/admin/getDocsItems')
