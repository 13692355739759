import React, {useState, useEffect} from "react";
import assetsStore from "../../../../zustand/assetsStore";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLink } from '@fortawesome/free-solid-svg-icons'

const PressItem = ({item}) => {
    const currentLanguage = assetsStore(state => state.currentLanguage)

    return (
        <div key={item._id} className="w-[97%] sm:w-[47%] md:w-[30.333%] h-[400px] flex flex-col justify-between rounded-xl flex-shrink-0 mx-[1.5%]
         bg-white dark:bg-bgDarker py-4 px-6 transition shadow-lg sm:hover:shadow-2xl sm:hover:scale-105 mt-12 sm:mt-0 overflow-hidden">
            <div className="w-full flex-grow text-sm xs:text-base"> 
                <p>{item.desc[currentLanguage]}</p>
            </div>
            <div className="w-full flex h-12 ">
                <img src={item.img} alt={item.link} className="h-full aspect-square rounded-full"/>
                <div className="flex flex-col justify-around flex-grow ml-4 ">
                    <h2 className="font-bold">{item.name[currentLanguage]}</h2>
                    <p className="text-sm text-gray-300">{item.link.slice(8, item.link.slice(8).indexOf("/") + 8)}</p>
                </div>
                <div className="h-full aspect-square flex justify-center items-center text-xl cursor-pointer" 
                onClick={() => window.open(item.link, "_blank")}>
                    <FontAwesomeIcon icon={faLink} />
                </div>
            </div>
        </div>
    )
}

export default PressItem