import create from 'zustand'
import * as api from '../api/index'
import { devtools } from 'zustand/middleware'
import axios from 'axios'

const store = (set) => ({
    isContactOpen: false,
    formOption: 'none',
    setIsContactOpen: (value) => {
        set((state) => ({isContactOpen: value}))
    },
    setFormOption: (value) => {
        set((state) => ({formOption: value}))
    },
    sendMessage: async (type, data) => {
        let body = [type, data]
        try{
            api.sendMessage(body)     
        }catch(err){
            console.log(err)
        }
    },
    sendVolounteerMessage: async (data, setErrorMsg) => {
        let body = ["volunteer", {name: `${data.name} ${data.lastName}`, contact: data.email, message: data.msg}]
        try{
            api.sendMessage(body)     
        }catch(err){
            console.log(err)
            setErrorMsg("Coś poszło nie tak")
        }
    },
    sendDonate: async (data, isMsg, setErrorMsg, txtItems) => {
        try{
            let body = {
                amount: data.amount,
                customer_email: data.email,
                customer_first_name: data.name,
                customer_last_name: data.lastName,
                org_id: 37540,
                terms_accepted: true,
            }
            const result = await axios.post("https://fanimani.pl/api/v2/fanipay/payment-link/", body)
            window.open(result.data.link, '_blank').focus()
            setErrorMsg(txtItems?.['support-thanks'])
            if(isMsg){
                let bodyOfMail = ["donate", {name: `${data.name} ${data.lastName}`, contact: data.email, message: data.msg}]
                await api.sendMessage(bodyOfMail)  
            }
        }catch(err){
            console.log(err)
            setErrorMsg("Coś poszło nie tak")
        }
    },
})


let  messagesStore 

if(process.env.PRODUCTION === "true"){
    messagesStore = create(store)
}else{
    messagesStore = create(devtools(store))
}

export default messagesStore