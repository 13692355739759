import React, {useEffect, useState} from "react";
import logo from '../../assets/logo.png'
import optionsStore from "../../zustand/optionsStore";
import assetsStore from "../../zustand/assetsStore";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookSquare, faFacebookF } from '@fortawesome/free-brands-svg-icons'
import { faLightbulb, faMoon, faChevronDown, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import {Link, useNavigate, useLocation} from 'react-router-dom'
import Button from "../Button/Button";
import {motion, AnimatePresence, useScroll} from 'framer-motion'
import { lineTop, lineMiddle, lineBottom, menu } from "../../animations/animations";
import MenuItem from "./MenuItem/MenuItem";

const Nav = () => {
    const windowSize = optionsStore(state => state.windowSize)
    const darkMode = optionsStore(state => state.darkMode)
    const { scrollYProgress } = useScroll()
    let [scrollPosition, setScrollPosition] = useState(0)
    const changeDarkMode = optionsStore(state => state.changeDarkMode)
    const languages = assetsStore(state => state.languages)
    const currentLanguage = assetsStore(state => state.currentLanguage)
    const changeLanguage = assetsStore(state => state.changeLanguage)
    const txtItems = assetsStore(state => state.txtItems)
    const [isOnTop, setIsOnTop] = useState(true)
    const [isLogoHidden, setIsLogoHidden] = useState(false)
    const [isOptionsOpen, setIsOptionsOpen] = useState(false)
    const [isLanguageOptionsOpen, setIsLanguageOptionsOpen] = useState(false)
    const navigate = useNavigate()
    const location = useLocation()
    const handleScroll = () => {
        if(windowSize > 1100){
            if(window.scrollY === 0){
                setIsOnTop(true)
            }else{
                setIsOnTop(false)
            }
        }else{
            setIsOnTop(true)
        }
    }
    window.addEventListener("scroll", handleScroll);
    const handleClickButton = async () => {
        await navigate('/home')
        await setIsOptionsOpen(false)
        document.getElementById('support').scrollIntoView({behavior: "smooth", block: "start"})
    }

    const handleNextLanguage = async () => {
        console.log(languages.findIndex(i => i._id === currentLanguage) + 1)
        if(languages.findIndex(i => i._id === currentLanguage) + 1 < languages.length) {
            changeLanguage(languages[languages.findIndex(i => i._id === currentLanguage) + 1]._id)
        }else{
            changeLanguage(languages[0]._id)
        }
        
    }

    useEffect(() => {
        window.addEventListener("scroll", () => {
            setScrollPosition(window.pageYOffset)
        })
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0)
        setIsOptionsOpen(false)
    }, [location])

    useEffect(() => {
        if(location.pathname === "/home"){
            let elem = document.getElementById('textLogo')
            let positions = elem.getBoundingClientRect()
            if(positions.top > 0){
                setIsLogoHidden(false)
            }else{
                setIsLogoHidden(true)
            }
        }

    }, [scrollPosition])

    return(
        <>
        <div className="w-full h-16 fixed top-0 left-0 px-2 md:px-8 z-[6] flex justify-between items-center bg-white dark:bg-bgDarker transition dark:text-white  "
         style={{background: isOnTop && 'transparent' }}>
            {windowSize > 1100 && <div className="w-1/3 h-full flex items-center z-[6]">
                <span className="transition ml-4 text-[1.8rem] cursor-pointer" onClick={() => {changeDarkMode(darkMode)}}
                style={{color: darkMode ? '#e4e019' : '#0f0d72'}}>
                    <FontAwesomeIcon icon={darkMode ? faLightbulb : faMoon} />
                </span>
                <a href="https://www.facebook.com/FundacjaCorIgnis/" target="_blank">
                    <span className="transition ml-4 text-[2rem] cursor-pointer text-[#4267B2]">
                        <FontAwesomeIcon icon={faFacebookSquare} />
                    </span>
                </a>
                <div className="ml-4 flex items-center cursor-pointer relative " >
                    <div className="w-[2rem] h-[1.8rem] mr-2 border-2 border-solid border-black rounded z-[5]" 
                    onClick={() => setIsLanguageOptionsOpen(!isLanguageOptionsOpen)}>
                        <img className="w-full h-full" src={languages?.filter(i => i._id === currentLanguage)[0].img} alt="flag" />
                    </div>
                    <motion.span initial={{roate: '0deg'}} animate={{rotate: isLanguageOptionsOpen ?'180deg' : '0deg'}}
                    onClick={() => setIsLanguageOptionsOpen(!isLanguageOptionsOpen)}>
                        <FontAwesomeIcon icon={faChevronDown} />
                    </motion.span>
                    <AnimatePresence>
                    {isLanguageOptionsOpen && <motion.div initial={{opacity: 0}} 
                    animate={{opacity: 1}} exit={{opacity: 0}} 
                     className="absolute top-[2rem] left-0 w-36 flex flex-col z-[4]">
                        {languages.map(item => (
                        <>{item._id !== currentLanguage &&
                        <div key={item._id} className="w-full flex items-center mt-4 z-[4]" onClick={() => changeLanguage(item._id)}>
                            <div className="w-[2rem] h-[1.8rem] mr-2 border-2 border-solid border-black rounded">
                                <img className="w-full h-full" src={item.img} alt="flag" />
                            </div>
                            <p>{item.name}</p>
                        </div>}</>))}
                    </motion.div>}
                    </AnimatePresence>
                </div>
                
            </div>}
            {windowSize > 1100 && <> {location.pathname === "/home" ? 
            <div className="w-1/3 h-full flex justify-center items-center overflow-hidden">
                <motion.div className="h-full flex items-center cursor-pointer" style={{scale: isOnTop && '1.1'}}
                initial={{scale: 0.8, y: 50}} onClick={() => navigate('/home')}
                animate={{scale: isOnTop ? 1.1 : 1, y: isLogoHidden ? 0 : 50, transition: {duration: .2}}}>
                    <img className="h-3/6" src={logo} alt="Logo fundacji Cor Ignis" />
                    <h3 className="text-2xl font-bold uppercase ml-4 tracking-wide" style={{fontFamily: 'Playfair Display,serif'}} >Cor Ignis</h3>
                </motion.div>
            </div>
            :<motion.div className="w-1/3 h-full justify-center flex items-center cursor-pointer" style={{scale: isOnTop && '1.1'}}
            initial={{scale: 0.8}} animate={{scale: isOnTop ? 1.1 : 1}} onClick={() => navigate('/home')}>
                <img className="h-3/6" src={logo} alt="Logo fundacji Cor Ignis" />
                <h3 className="text-2xl font-bold uppercase ml-4 tracking-wide" style={{fontFamily: 'Playfair Display,serif'}} >Cor Ignis</h3>
            </motion.div>}</>}
            <div className="w-full md:w-1/3 justify-end h-full flex items-center">
                {windowSize > 1100 && <Link to="/contact">
                    <p className="text-xl mr-4 transition hover:scale-[1.1] ">{txtItems?.['contact']}</p>
                </Link>}
                {windowSize > 1100 && <Link to="/team">
                    <p className="text-xl mr-4 transition hover:scale-[1.1] ">{txtItems?.['nav-team']} </p>
                </Link>}
                {windowSize > 1100 && <Button style={{padding: '0rem 1rem', color: "white", height: '70%', 
                }} text={txtItems?.['wantToHelp']}
                 onClick={handleClickButton}/>}
                 {windowSize < 1100 && <div onClick={() => setIsOptionsOpen(!isOptionsOpen)}
                 className="ml-2 h-[70%] aspect-square rounded-lg bg-primarySoft flex flex-col justify-around items-end px-2 py-3 z-[11]">
                    <motion.div variants={lineTop} initial="initial"
                    animate={isOptionsOpen ? "animate" : "initial"} className="w-full h-1/6 rounded-full bg-white"></motion.div>
                    <motion.div variants={lineBottom} initial="initial" animate={isOptionsOpen ? "animate" : "initial"}
                    className="w-full h-1/6 bg-white rounded-full"></motion.div>
                </div>}
            </div>
        </div>
        {windowSize > 1100 &&<motion.div className="w-full h-2 fixed top-[4rem] left-0 bg-primarySoft z-[6]"
        style={{ scaleX: isOnTop ? 0 : scrollYProgress }}></motion.div>}
        <AnimatePresence>
        {isOptionsOpen && 
            <motion.div variants={menu} initial="initial" animate="animate" exit="initial"
            className="fixed h-screen shadow-2xl pb-2 top-0 right-0 bg-white dark:bg-bgDark flex flex-col z-[6]" style={{width: 'calc(100% - 60px)'}}>
                <div className="flex flex-grow flex-col items-center overflow-scroll">
                    <MenuItem text={txtItems?.['nav-home']} page="/home" section='header' setIsOptionsOpen={setIsOptionsOpen} />
                    <MenuItem text={txtItems?.['nav-team']} page="/team" section='header' setIsOptionsOpen={setIsOptionsOpen}  />
                    <MenuItem text={txtItems?.['nav-contact']} page="/contact" section='header' setIsOptionsOpen={setIsOptionsOpen}  />
                    <MenuItem text={txtItems?.['nav-terms']} page="/terms" section='header' setIsOptionsOpen={setIsOptionsOpen} />
                    <MenuItem support text={txtItems?.['nav-support']} page="/home" section='support' setIsOptionsOpen={setIsOptionsOpen} />
                </div>
                <div className="w-full h-16 flex justify-around items-center px-2">
                    <div className="w-[31%] h-full flex justify-center items-center text-xl border-2 border-solid rounded-xl" 
                    style={{backgroundColor: darkMode ? 'rgba(228, 224, 25, 0.4)' : 'rgba(15, 13, 114, 0.4)', 
                    color: darkMode ? '#e4e019' : '#0f0d72', borderColor: darkMode ? '#e4e019' : '#0f0d72'}} onClick={() => {changeDarkMode(darkMode)}}>
                        <FontAwesomeIcon icon={darkMode ? faLightbulb : faMoon} />
                    </div>
                    <a href="https://www.facebook.com/FundacjaCorIgnis/" target="_blank" style={{backgroundColor: 'rgba(66, 103, 178, 0.4)'}}
                     className="w-[31%] h-full flex justify-center items-center text-xl border-2 border-solid rounded-xl 
                        text-[#4267B2] border-[#4267B2]" >
                            <FontAwesomeIcon icon={faFacebookF} />
                    </a>
                    <div className="w-[31%] h-full flex justify-center items-center text-xl border-2 border-gray-300 dark:border-black border-solid rounded-xl" 
                    style={{backgroundColor: darkMode ? 'rgba(0, 0, 0, 0.4)' : 'rgba(209, 213, 219, 0.4)'}} onClick={handleNextLanguage} >
                        <div className="w-[3rem] h-[2rem]  border-2 border-solid border-white dark:border-black rounded z-[5]" 
                        >
                            <img className="w-full h-full" src={languages?.filter(i => i._id === currentLanguage)[0].img} alt="flag" />
                        </div>
                    </div>
                </div>
            </motion.div>
        }</AnimatePresence>
        {isOptionsOpen &&<div onClick={() => setIsOptionsOpen(false)}
        className="fixed w-screen h-screen top-0 left-0 z-[3]"></div>}
        </>
    )
}

export default Nav


