import React, {useState, useEffect, useRef} from "react";
import assetsStore from "../../../zustand/assetsStore";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown} from '@fortawesome/free-solid-svg-icons';
import { motion } from "framer-motion";
import draftToHtml from 'draftjs-to-html';

const TermsItem = ({item, index}) => {
    const currentLanguage = assetsStore(state => state.currentLanguage)
    const [isOpen, setIsOpen] = useState(false)
    const [itemContent, setItemContent] = useState()
    const [height, setHeight] = useState(0)
    const ref = useRef(null)

    useEffect(() => {
        const createHTML = async () => {
            let markup = await draftToHtml(JSON.parse(item.desc[currentLanguage]?.converted))
            setItemContent(markup)
        }
        createHTML()
    }, [currentLanguage])

    useEffect(() => {
        setHeight(ref.current.clientHeight)
    }, [ref])

    return (
        <>{item.name[currentLanguage] && 
            <motion.div  id={`doc${item._id}`} className='w-full  flex flex-col mt-4 overflow-hidden transition-all' initial={{maxHeight: `${height}px`}} 
            animate={{maxHeight: isOpen ? '300vh' :  `${height}px`, transition: {duration: 1.5, type: 'linear', delay: 0}}} >
                <div  className='w-full flex justify-between cursor-pointer items-center ' onClick={() => setIsOpen(!isOpen)}>
                    <p ref={ref} className="font-bold">{item.name[currentLanguage]}</p>
                    <div className="h-full aspect-square flex justify-end items-center">
                        <motion.span initial={{rotate: '0deg'}} animate={{rotate: isOpen ? '180deg' : '0deg'}}>
                            <FontAwesomeIcon icon={faChevronDown}/>
                        </motion.span>
                    </div>
                </div>
                <div className="w-full mt-2" dangerouslySetInnerHTML={{ __html: itemContent }}>
                </div> 
            </motion.div>
        }</>
    )
}

export default TermsItem