import React, {useState, useEffect} from "react";
import assetsStore from "../../../zustand/assetsStore";
import NewsItem from "./NewsItem/NewsItem";
import {motion, AnimatePresence, useScroll} from 'framer-motion'

const News = () => {
    const txtItems = assetsStore(state => state.txtItems)
    const currentLanguage = assetsStore(state => state.currentLanguage)
    const newsItems = assetsStore(state => state.newsItems)
    const { scrollXProgress } = useScroll()

    useEffect(() => {
        console.log(scrollXProgress)
    }, [])

    return (
        <div className='w-full md:w-smContent lg:w-content m-auto my-12 px-4 dark:text-white '>
            <h2 className='text-2xl text-primarySoft'>{txtItems?.['news-title']}</h2>
            <div id="news" className="w-full h-full mt-4 pb-8 flex overflow-x-scroll">
                {newsItems?.filter(i => i.language === currentLanguage)?.map(item => <NewsItem item={item} />)}
            </div>
        </div>
    )
}

export default News