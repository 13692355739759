import React, {useState, useEffect} from "react";
import {Link, useNavigate, useLocation} from 'react-router-dom'
import assetsStore from "../../zustand/assetsStore";
import optionsStore from "../../zustand/optionsStore";
import Header from '../../components/Header/Header'
import draftToHtml from 'draftjs-to-html';

const Blog = () => {
    const location = useLocation()
    const newsItems = assetsStore(state => state.newsItems)
    const currentLanguage = assetsStore(state => state.currentLanguage)
    const isMobile = optionsStore(state => state.isMobile)
    const txtItems = assetsStore(state => state.txtItems)
    const navigate = useNavigate()

    const [isLoaded, setIsLoaded] = useState(false)
    const [item, setItem] = useState({})
    const [itemContent, setItemContent] = useState()

    useEffect(() => {
        if(location.pathname.slice(0, 6) === '/blog/'){
            let item = newsItems.filter(i => i._id === location.pathname.slice(6))
            if(item.length === 1){
                if(item[0].type === "blog"){
                    setItem(item[0])
                    console.log(item[0])
                    const createHTML = async () => {
                        let markup = await draftToHtml(JSON.parse(item[0].typeValue.blogConverted))
                        setItemContent(markup)
                    }
                    createHTML()
                }else{
                    navigate('/home')
                }
            }else{
                navigate('/home')
            }
        }else{
            navigate('/home')
        }
    }, [])

    return (
        <div className='w-full h-full flex flex-col'>
            <Header title={item?.title} img={item?.typeValue?.blogImg} desc={item?.description} elementId='Content' cta={txtItems?.['readMore']} />
            <div id="Content" className="w-full md:w-smContent lg:w-content m-auto mb-12 px-4 dark:text-white ">
                {isMobile && <p className="font-bold text-[18px]">{item?.description}</p>}
                {isMobile && <img src={item?.img} className="w-full my-4" />}
                <div id="blogContent" className="w-full mt-2" dangerouslySetInnerHTML={{ __html: itemContent }}>
                </div> 
            </div>
        </div>
    )
}

export default Blog