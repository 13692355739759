import React, {useState, useEffect} from "react";
import assetsStore from "../../../zustand/assetsStore";
import optionsStore from "../../../zustand/optionsStore";
import AboutUsItem from "./AboutUsItem/AboutUsItem";
import ignitoriumImg1 from '../../../assets/undraw_art_lover_re_fn8g 1.png'
import ignitoriumImg2 from '../../../assets/undraw_My_location_re_r52x-removebg-preview.png'
import ignitoriumImg3 from '../../../assets/undraw_Showing_support_re_5f2v-removebg-preview.png'
import ignitoriumImg4 from '../../../assets/construcion2.png'
import ignitoriumImg5 from '../../../assets/ignitoriumImg5.png'
import {motion} from 'framer-motion'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";

const AboutUs = () => {
  const txtItems = assetsStore(state => state.txtItems)
  const currentLanguage = assetsStore(state => state.currentLanguage)
  const darkMode = optionsStore(state => state.darkMode) 
  const windowSize = optionsStore(state => state.windowSize) 
  const [itemIndex, setItemIndex] = useState(0)
  const [timeOutId, setTimeOutId] = useState(0)
  const [isOpen, setIsOpen] = useState(true)
  const defaultItems = [
    {
      id: 1,
      img: ignitoriumImg1,
    },
    {
      id: 2,
      img: ignitoriumImg2,
    },
    {
      id: 3,
      img: ignitoriumImg3,
    },
    {
      id: 4,
      img: ignitoriumImg4,
    },
  ]
  const [items, setItems] = useState(defaultItems)



  useEffect(() => {
    if(itemIndex === items.length - 1){
      let newItems = [...items, ...defaultItems]
      setItems(newItems)
    }
    clearTimeout(timeOutId)
    let id = setTimeout(() => setItemIndex(itemIndex => itemIndex + 1), 7500)
    setTimeOutId(id)
  }, [itemIndex])


  return (
      <div id="ignitorium" className="w-11/12 sm:mt-24 md:mt-36 lg:mt-48 md:w-smContent lg:w-content mx-auto sm:h-[350px] md:h-[275px]  dark:text-white  flex flex-col sm:flex-row justify-between items-center z-[3]">
        <div className="w-full sm:w-1/3 md:h-full flex flex-col justify-center sm:pr-4">
          <div className=" flex sm:flex-col justify-between sm:justify-start items-center sm:items-start cursor-pointer sm:cursor-auto" onClick={() => windowSize < 700 && setIsOpen(!isOpen)}>
            <h2 className="text-2xl text-primarySoft md:text-primary md:text-4xl md:mb-4">{txtItems?.['about-title']}</h2>
            {windowSize > 700 ? <p className="text-sm sm:text-xs md:text-base">{txtItems?.['fundationDesc']}</p> : 
            <div className="h-full aspect-square flex justify-center items-center text-primarySoft text-2xl">
              <FontAwesomeIcon icon={isOpen ? faMinus : faPlus} />
            </div>}
          </div>
        </div>
        {windowSize > 700 ? <div id="ignitoriumSlider" className="w-full sm:w-3/5 lg:w-1/2  overflow-x-hidden h-[100%]  " >
          <motion.div className="w-full h-full flex items-center" animate={{x: `-${windowSize > 700 ? itemIndex * 85 - 7.5 : itemIndex * 100}%`, transition: {type: 'linear', duration: .3}}}>
            {items?.map((item, index) => (<AboutUsItem item={item} index={index} setItemIndex={setItemIndex} />))}
          </motion.div>
        </div> : 
        <motion.div className="w-full flex flex-col overflow-hidden" animate={{maxHeight: isOpen ? '9999px' : '0px', transition: {duration: 1.4} }}>
          <AboutUsItem item={{id: 5, img: ignitoriumImg5}} />
          {defaultItems?.map(item => <AboutUsItem item={item} />)}
        </motion.div>}
    </div>
  )
}

export default AboutUs