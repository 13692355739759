import React, {useState, useEffect} from "react";
import {motion} from 'framer-motion'

const ImageSchowcase = ({img, title, setIsShowcaseOpen}) => {
    
    const handleScroll = () => {
        setIsShowcaseOpen(false)
    }

    useEffect(() => {
        // window.addEventListener('scroll', handleScroll);
    }, [])

    return(
        <div className="w-full h-screen fixed z-30 top-0 left-0 flex flex-col justify-center items-center">
                <motion.div className="absolute top-0 left-0 w-full h-full  cursor-pointer" style={{backgroundColor: "rgba(0, 0, 0, 0.637)"}}
                initial={{backgroundColor: 'rgba(0, 0, 0, 0.1)'}} animate={{backgroundColor: 'rgba(0, 0, 0, 0.5)'}} 
                 onClick={() => setIsShowcaseOpen(false)}></motion.div>
                <motion.img initial={{opacity: 0, scale: 0.7}} animate={{opacity: 1, scale: 1}} 
                className="max-w-[90vw] z-[31]md:max-w-[70vw] max-h-[60vh] lg:max-w-[50vw] lg:max-h-[50vh] z-[31]" src={img} alt="" />
                <motion.p initial={{opacity: 0, scale: 0.7}} animate={{opacity: 1, scale: 1}} className="z-[31] mt-12 text-lg text-center w-3/4 sm:w-1/3 text-white">{title}</motion.p>
        </div>
    )
}

export default ImageSchowcase