import React, {useEffect,} from "react";
import Support from "./Support/Support";
import Header from "../../components/Header/Header";
import News from "./News/News";
import AboutUs from "./AboutUs/AboutUs";
import Succeses from "./Succeses/Succeses";
import Press from "./Press/PressNew";
import Brands from "./Brands/Brands";

const MainView = () => {

    return(
    <div className="w-full min-h-[100vh] flex flex-col">
        <Header main />
        <News />
        <AboutUs />
        <Succeses />
        <Brands />
        <Press />
        <Support /> 
    </div>
    )
}

export default MainView